/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// common components
import Input from "../../../components/input-fields/Input";
import SingleSelect from "../../../components/input-fields/SingleSelect";
import InputDate from "../../../components/input-fields/InputDate";
import Switch from "../../../components/input-fields/Switch";
import GenderOptions from "../../../utils/definitions/GenderOptions";
import provinceOptions from "../../../utils/definitions/ProvinceOption";

// default avatar
import imgDefault from "../../../assets/imgs/defaultAvatar.png";

//utils
import { resizeFile, getFileDetails } from "../../../utils/file";
import SwimmingOptions from "../../../utils/definitions/SwimmingOptions";
import { deepClone } from "../../../utils/tools";

// backend service
import api from "../../../services/api";
import request from "../../../services/Request";

// scss
import "../../../scss/views/ChildProfile/childForms/ChildDetailsForm.scss";

function ChildDetailsForm({
  childSelected,
  childDetails,
  formValidation,
  setFormValidation,
  setChildDetails,
  toggleValues,
  setToggleValues,
  showNotification,
  validatePhotoUpload,
  setValidatePhotoUpload,
  jwtToken,
}) {
  const { t } = useTranslation();
  const [uploadingAvatar, setUploadingAvatar] = useState(false);
  const swimmingOptions = SwimmingOptions();
  const genderOptions = GenderOptions();

  const handleOnSwimmingConsentChange = (value, id) => {
    const tempValidation = { ...formValidation };

    // reset formValidation
    tempValidation[id].message = "";
    tempValidation[id].valid = true;
    setFormValidation({ ...tempValidation });

    // set new value
    const tempChildDetails = deepClone(childDetails); // deep copy
    tempChildDetails[id] = value;
    setChildDetails(tempChildDetails);
  };

  const DisplaySwimmingConsent = () => {
    return (
      <div className="swimming">
        <label>{t("childProfile.swimmingTitle")}*</label>
        <SingleSelect
          id="swimming"
          value={childDetails.swimming}
          options={[...swimmingOptions]}
          onChange={handleOnSwimmingConsentChange}
          placeHolder={t("childProfile.swimmingPlace")}
          validation={formValidation["swimming"]}
        />
      </div>
    );
  };
  // upload child avatar
  const handleUploadPhoto = async (e) => {
    const { files } = e.target;

    // get file name and type
    const fileDetails = getFileDetails(files[0]);

    if (files[0]) {
      // only accepting jpeg
      if (fileDetails.type !== "image/jpeg") {
        showNotification(
          "warning",
          t("childProfile.wrongPhotoTitle"),
          t("childProfile.wrongPhotoMessage")
        );
        return;
      }

      //compress the photo file to make it suitable for avatar file
      const compressedFile = await resizeFile(files[0]);

      try {
        setUploadingAvatar(true);

        // call api to get the s3 credentials
        const s3CredentialResult = await request.post(
          api.childPhoto,
          {
            childId: childSelected.childId,
          },
          jwtToken
        );

        switch (s3CredentialResult.status) {
          case 200:
            // proceed to upload
            const { url, fields } = s3CredentialResult.data;

            // create form for upload
            const formData = new FormData();

            Object.keys(fields).forEach((key) => {
              formData.append(key, fields[key]);
            });
            formData.append("file", compressedFile);

            // upload to s3
            const uploadResult = await request.post(
              url,
              formData,
              false,
              fields.bucket
            );
            if (uploadResult.status === 204) {
              // set image to its new image
              document.getElementById("child-avatar").src = `${
                process.env.REACT_APP_S3_PATH
              }/camp/child/${childSelected.childId}/avatar?t=${Math.random()}`;
              setValidatePhotoUpload(true);
            } else {
              showNotification("system");
            }
            break;

          case 403:
            showNotification("expired");
            break;

          default:
            throw new Error("system-error");
        }
      } catch (e) {
        if (e.message === "system-error") {
          showNotification("system");
        } else {
          showNotification("network");
        }
      } finally {
        setUploadingAvatar(false);
      }
    }
  };

  const handleInputChange = (value, id) => {
    const tempValidation = { ...formValidation };

    // reset formValidation
    tempValidation[id].message = "";
    tempValidation[id].valid = true;
    setFormValidation({ ...tempValidation });

    // set new value
    const tempChildDetails = { ...childDetails };
    tempChildDetails[id] = value;
    setChildDetails(tempChildDetails);
  };

  const handleToggleChange = (value, id) => {
    const tempValidation = { ...formValidation };
    const tempToggleValues = { ...toggleValues };
    const tempChildDetails = { ...childDetails };

    const newToggleValue = !toggleValues[id]; // new toggle value

    if (newToggleValue) {
      tempValidation[id].rules = ["required"]; // make field required
    } else {
      tempValidation[id].rules = []; // field has no validation
      tempChildDetails[id] = ""; // empty out the value
    }

    tempValidation[id].message = "";
    tempValidation[id].valid = true;
    tempToggleValues[id] = !toggleValues[id];

    setFormValidation({ ...tempValidation });
    setToggleValues({ ...tempToggleValues });
    setChildDetails(tempChildDetails);
  };
  return (
    <div className="child-details-form">
      <h3> {t("childProfile.childInformation")}</h3>
      <div className="childPhotoSection">
        <div className="childPhotoSection__form-title">
          {t("childProfile.childPhoto")}
        </div>
        <div className="child-avatar-form">
          <img
            id="child-avatar"
            name="child"
            src={`${process.env.REACT_APP_S3_PATH}/camp/child/${
              childSelected?.childId
            }/avatar?t=${Math.random()}`}
            alt="avatar"
            onErrorCapture={(e) => {
              e.target.name = "default"; // indicating child has no avatar image
              e.target.src = imgDefault;
            }}
          />
          <div className="upload-field">
            {!validatePhotoUpload && (
              <div className="photo-validation-container">
                <span>{t("childProfile.photoValidation")}</span>
              </div>
            )}
            <div className="uploadAvatar">
              {uploadingAvatar ? (
                <div className="loading"></div>
              ) : (
                <>
                  <label htmlFor="uploadAvatar">
                    {t("childProfile.uploadButton")}
                  </label>

                  <input
                    name="uploadAvatar"
                    id="uploadAvatar"
                    type="file"
                    accept="image/jpeg"
                    onChange={handleUploadPhoto}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="child-details-form__group">
        <Input
          id="childName"
          label={`${t("childProfile.childName")}*`}
          type="text"
          value={childDetails["childName"]}
          placeHolder={""}
          disabled={true}
          setter={handleInputChange}
          validation={formValidation["childName"]}
        />
        <InputDate
          id="birthday"
          label={`${t("childProfile.dateOfBirth")}*`}
          type="text"
          value={childDetails["birthday"]}
          placeHolder={""}
          setter={handleInputChange}
          validation={formValidation["birthday"]}
        />
        <div className="select-wrapper">
          <label>{`${t("childProfile.gender")}*`}</label>
          <SingleSelect
            id="gender"
            value={childDetails["gender"]}
            options={[...genderOptions]}
            onChange={handleInputChange}
            placeHolder={`${t("childProfile.genderPlaceholder")}`}
            validation={formValidation["gender"]}
          />
        </div>
        <Input
          className="two-cols"
          id="address"
          label={`${t("childProfile.address")}*`}
          type="text"
          value={childDetails["address"]}
          placeHolder={""}
          setter={handleInputChange}
          validation={formValidation["address"]}
        />
        <Input
          id="city"
          label={`${t("childProfile.city")}*`}
          type="text"
          value={childDetails["city"]}
          placeHolder={""}
          setter={handleInputChange}
          validation={formValidation["city"]}
        />
        <div className="select-wrapper">
          <label>{`${t("childProfile.province")}*`}</label>
          <SingleSelect
            id="province"
            value={childDetails["province"]}
            options={[...provinceOptions]}
            onChange={handleInputChange}
            placeHolder={`${t("childProfile.provincePlaceholder")}`}
            validation={formValidation["province"]}
          />
        </div>
        <Input
          className="two-cols-offset"
          id="postCode"
          label={`${t("childProfile.postalCode")}*`}
          type="text"
          value={childDetails["postCode"]}
          placeHolder={""}
          setter={handleInputChange}
          validation={formValidation["postCode"]}
        />
        <Input
          id="doctorName"
          label={`${t("childProfile.doctorName")}*`}
          type="text"
          value={childDetails["doctorName"]}
          placeHolder={""}
          setter={handleInputChange}
          validation={formValidation["doctorName"]}
        />
        <Input
          className="two-cols-offset"
          id="careCard"
          label={`${t("childProfile.careCard")}*`}
          type="text"
          value={childDetails["careCard"]}
          placeHolder={""}
          setter={handleInputChange}
          validation={formValidation["careCard"]}
        />
        {/* allergies */}
        <div className="toggle-wrapper">
          <label>{`${t("childProfile.allergies")}`}</label>
          <Switch
            value={toggleValues.allergies}
            onChange={(e) => handleToggleChange(e, "allergies")}
          />
        </div>
        {toggleValues.allergies && (
          <Input
            className="full details"
            id="allergies"
            label={`${t("childProfile.provideDetails")}${
              toggleValues.allergies ? "*" : ""
            }`}
            type="text"
            value={childDetails["allergies"]}
            placeHolder={""}
            setter={handleInputChange}
            validation={formValidation["allergies"]}
          />
        )}

        {/* disabilities */}
        <div className="toggle-wrapper">
          <label>{`${t("childProfile.disabilities")}`}</label>
          <Switch
            value={toggleValues.disabilities}
            onChange={(e) => handleToggleChange(e, "disabilities")}
          />
        </div>
        {toggleValues.disabilities && (
          <Input
            className="full details"
            id="disabilities"
            label={`${t("childProfile.provideDetails")}${
              toggleValues.disabilities ? "*" : ""
            }`}
            type="text"
            value={childDetails["disabilities"]}
            placeHolder={""}
            setter={handleInputChange}
            validation={formValidation["disabilities"]}
          />
        )}

        {/* medical */}
        <div className="toggle-wrapper">
          <label>{`${t("childProfile.medicalConditions")}`}</label>
          <Switch
            value={toggleValues.medical}
            onChange={(e) => handleToggleChange(e, "medical")}
          />
        </div>
        {toggleValues.medical && (
          <Input
            className="full details"
            id="medical"
            label={`${t("childProfile.provideDetails")}${
              toggleValues.medical ? "*" : ""
            }`}
            type="text"
            value={childDetails["medical"]}
            placeHolder={""}
            setter={handleInputChange}
            validation={formValidation["medical"]}
          />
        )}

        {/* specialNeeds */}
        <div className="toggle-wrapper">
          <label>{`${t("childProfile.specialNeeds")}`}</label>
          <Switch
            value={toggleValues.specialNeeds}
            onChange={(e) => handleToggleChange(e, "specialNeeds")}
          />
        </div>
        {toggleValues.specialNeeds && (
          <Input
            className="full details"
            id="specialNeeds"
            label={`${t("childProfile.provideDetails")}${
              toggleValues.specialNeeds ? "*" : ""
            }`}
            type="text"
            value={childDetails["specialNeeds"]}
            placeHolder={""}
            setter={handleInputChange}
            validation={formValidation["specialNeeds"]}
          />
        )}
        <DisplaySwimmingConsent />
      </div>
    </div>
  );
}

export default ChildDetailsForm;
