const ChildProfile = {
  selectOrAddChildPlace: "Sélectionner ou ajouter enfant",
  addAnotherChild: "Ajouter un autre enfant",
  childInformation: "Infos Enfant",
  childName: "Nom Enfant",
  dateOfBirth: "Date de Naissance",
  gender: "Genre",
  genderPlaceholder: "Sélectionner genre",
  address: "Adresse",
  city: "Ville",
  province: "Province",
  provincePlaceholder: "Sélectionner province",
  postalCode: "Code Postal",
  doctorName: "Nom Médecin",
  provideDetails: "Fournir Détails",
  disabilities: "Handicaps",
  allergies: "Allergies",
  medicalConditions: "Conditions Médicales",
  specialNeeds: "Besoins Spéciaux",
  careCard: "Carte Soins",
  pickupList: "Liste de Ramassage",
  name: "Nom",
  phoneNumber: "Numéro de Téléphone",
  addPickUpList: "Ajouter Liste de Ramassage",
  deepWater: "Profond",
  shoulderHigh: "Épaules",
  waistHigh: "Taille",
  lifeJacket: "Gilet de Sauvetage",
  noSwimming: "Pas de Natation",
  yes: "Oui",
  no: "Non",
  swimmingTitle: "Capacité de Natation",
  swimmingPlace: "Niveau",
  consents: "Consentements",
  yesOrNo: "Oui/Non",
  consent_0: "1. Expériences hors domicile et en garderie.",
  consent_1: "2. Autorisation d'application de crème solaire.",
  consent_2: "3. Autorisation de sorties.",
  consent_3: "4. Autorisation de photographier l'enfant.",
  consent_4: "5. Personnes interdites d'accès à l'enfant.",
  consent_4Add: "Fournir documents à la garderie.",
  consent_5: "6. Vaccination de l'enfant.",
  consent_5Add: "Fournir copie des vaccins avant participation.",
  male: "Homme",
  female: "Femme",
  other: "Autre",
  unknown: "Inconnu",
  submit: "Soumettre",
  cancel: "Annuler",
  savedTitle: "Enregistré",
  savedMessage: "Informations enfant enregistrées.",
  childPhoto: "Photo Enfant*",
  uploadButton: "+ Télécharger",
  photoValidation: "Photo requise",
  wrongPhotoTitle: "Format Photo Incorrect",
  wrongPhotoMessage: "Seuls les formats JPG et JPEG autorisés",
};

export default ChildProfile;
