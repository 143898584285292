import { useTranslation } from "react-i18next";

function YesNoOptions() {
  const { t } = useTranslation();
  const yesNoOptions = [
    {
      label: t("childProfile.yes"),
      value: "1",
    },
    {
      label: t("childProfile.no"),
      value: "0",
    },
  ];
  return yesNoOptions;
}

export default YesNoOptions;
