import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// app context
import { useAuth } from "../../context/AuthContext";
import { useAppContext } from "../../context/AppContext";

// backend service
import api from "../../services/api";
import request from "../../services/Request";

// routes
import routes from "../../context/routes";

// styling
import "../../scss/views/Login/login.scss";

// setup password page
const SetupPassword = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const { userId, jwtToken } = useAuth();
  const { showNotification } = useAppContext();

  const [loading, setLoading] = useState(false); // loading button
  const [error, setError] = useState(null); // warning message

  // handle continue
  const handleContinue = async () => {
    const password = document.getElementById("password").value;
    const confirmed = document.getElementById("confirmed").value;

    // validate password
    if (!password || !confirmed) {
      setError(t("login.noPassword"));
      return;
    } else if (password !== confirmed) {
      setError(t("login.mismatchedPassword"));
      return;
    }

    // update user password
    try {
      setLoading(true);
      const res = await request.put(
        api.familyProfile,
        {
          userId: userId,
          type: "update-password",
          info: {
            password: password,
          },
        },
        jwtToken
      );

      switch (res.status) {
        case 200:
          history.replace({
            pathname: routes.USER_ACCOUNT,
            search: location.search,
          });
          break;

        case 403:
          showNotification("expired");
          break;

        default:
          throw new Error("system-error");
      }
    } catch (e) {
      if (e.message === "system-error") {
        showNotification("system");
      } else {
        showNotification("network");
      }
    } finally {
      setLoading(false);
    }
  };

  // render page
  return (
    <>
      <div className="title" style={{ marginBottom: 25 }}>
        {t("login.setupPassword")}
      </div>

      <div className="label">{t("login.newPassword")}</div>
      <input
        id="password"
        className="input-box"
        placeholder={t("login.newPassword")}
        type="password"
        onChange={() => {
          setError(null);
        }}
        disabled={loading}
      />
      <br />
      <br />
      <div className="label">{t("login.confirmPassword")}</div>
      <input
        id="confirmed"
        className="input-box"
        placeholder={t("login.confirmPassword")}
        type="password"
        onChange={() => {
          setError(null);
        }}
        disabled={loading}
      />
      <div className="error-message">{error}</div>

      <div className="continue-button" onClick={handleContinue}>
        {!loading ? t("common.confirm") : <div className="loading"></div>}
      </div>
    </>
  );
};

export default SetupPassword;
