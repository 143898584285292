import React from "react";
import "../../scss/components/button/WhiteButton.scss";

/**
 * @return{React.Component}
 */
function WhiteButton({ onClick, className, children }) {
  return (
    <button
      className={`white-btn ${className ? className : ""}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export default WhiteButton;
