import React, { useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// context and routes
import { useAuth } from "../../context/AuthContext";
import { useAppContext } from "../../context/AppContext";
import routes from "../../context/routes";

// backend service
import api from "../../services/api";
import request from "../../services/Request";

// common components
import BlueButton from "../../components/buttons/BlueButton";
import YellowButton from "../../components/buttons/YellowButton";
import GreyButton from "../../components/buttons/GreyButton";
import PaymentForm from "../../components/payment/PaymentForm";

// icons
import calendarWhite from "../../assets/icons/calendar-white.png";
import clockWhite from "../../assets/icons/clock-white.png";

// stripe utils
import { loadStripe } from "@stripe/stripe-js";

// utils
import { formatDateMonth } from "../../utils/tools";
import { activityStatus } from "../../utils/activityStatus";
import { serviceCharge, totalCharge } from "../../utils/tools";
import { mappingDays } from "../../utils/mappingDays";

// style
import "../../scss/views/Registration/Registration.scss";

// activity registration page
function Confirmation({ campConfig, setShowConfirmation }) {
  const creditCardRef = useRef(); // credit card ref

  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const { userId, jwtToken } = useAuth();
  const {
    superId,
    centerId,
    showLoading,
    showNotification,
    userData,
    updateUserData,
  } = useAppContext();
  const { centerInfo, familyPayment, selectedActivity } = userData;

  const [cardholderName, setCardholderName] = useState("");
  const [validName, setValidName] = useState(true);
  const [isMounted, setIsMounted] = useState(false); // tracks if the component is mounted or not
  const [stripeObject, setStripeObject] = useState(null);
  const [checked, setChecked] = useState(false); // check box for saving payment method

  useEffect(() => {
    setIsMounted(true);
    // eslint-disable-next-line
  }, []);

  // ----load stripe object only ONCE! -----
  useEffect(() => {
    const fetchStripeObject = async () => {
      const res = await loadStripe(
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
      );

      setStripeObject(res);
    };

    // perform fetchStripeObject only when component is mounted to prevent memory leak warning
    if (isMounted) {
      fetchStripeObject(); // cal fetchStripeObject
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);
  // ----load stripe object only ONCE! -----

  // operation status
  const aStatus = activityStatus(selectedActivity);

  // transaction fee and grand total
  const transFee = serviceCharge(
    campConfig.total,
    centerInfo.calcTrans,
    centerInfo.sRate
  );
  const grandTotal = totalCharge(
    campConfig.total,
    centerInfo.calcTrans,
    centerInfo.sRate
  );

  // warning messages
  const handle208 = (wType, wMessage) => {
    let title = wType;
    let message = wMessage;
    switch (wType) {
      case "restricted0":
        title = t("registration.restrictedTitle");
        message = t("registration.restrictedMessage0");
        break;

      case "restricted1":
        title = t("registration.restrictedTitle");
        message = `${t("registration.restrictedMessage1")} ${
          selectedActivity.info.resDate
        }.`;
        break;

      case "in-list":
        title = t("registration.inListTitle");
        message = t("registration.inListMessage");
        break;

      case "no-space":
        title = t("registration.noSpaceTitle");
        message = t("registration.noSpaceMessage");
        break;

      case "payment-failed":
        title = t("registration.paymentFailedTitle");
        message = `${t("registration.paymentFailedMessage")} ${wMessage}.`;
        showNotification("warning", title, message); // stay on confirmation page
        return;

      default:
      // do nothing
    }

    // display warning message
    showNotification("warning", title, message, () => {
      history.replace({
        pathname: routes.ACTIVITY_LIST, // back to activity list
        search: location.search,
      });
    });
  };

  // handle registration
  const handleRegistration = async () => {
    // validate card holder name
    if (!familyPayment && campConfig.total > 0) {
      if (!cardholderName) {
        setValidName(false);
        return;
      }
    }

    try {
      showLoading(true);

      // payment information
      const paymentInfo = {
        fee: campConfig.total,
        trans: transFee,
        amount: grandTotal,
      };

      // credit card payment method
      if (familyPayment) {
        // add to payment info
        paymentInfo.name = familyPayment.name;
        paymentInfo.id = familyPayment.method; // saved payment method
        paymentInfo.customer = familyPayment.customer; // customer attached with payment method
      } else if (campConfig.total > 0) {
        // stripe payment method
        const method = await creditCardRef.current.handlePay();
        if (!method) {
          showLoading(false);
          return;
        }

        if (!checked) {
          // add to payment info
          paymentInfo.name = cardholderName;
          paymentInfo.id = method.id; // new payment method
          paymentInfo.customer = null;
        } else {
          // saving card to family profile
          const res = await request.put(
            api.familyProfile,
            {
              type: "save-card",
              userId: userId,
              payment: {
                name: cardholderName,
                method: method.id,
                brand:
                  method.card.brand.charAt(0).toUpperCase() + // capitalized
                  method.card.brand.slice(1),
                card: `**** ${method.card.last4}`,
                expiry: `${method.card.exp_month}/${method.card.exp_year}`,
              },
            },
            jwtToken
          );

          switch (res.status) {
            case 200:
              // add to payment info
              paymentInfo.name = res.data.payment.name;
              paymentInfo.id = res.data.payment.method; // saved payment method
              paymentInfo.customer = res.data.payment.customer; // customer attached with payment method

              // update family payment
              updateUserData({
                familyPayment: res.data.payment,
              });
              break;

            case 403:
              showNotification("expired");
              break;

            default:
              throw new Error("system-error");
          }
        }
      }

      // registration with backend
      const res = await request.post(
        api.registration,
        {
          userId: userId,
          superId: superId,
          centerId: centerId,
          centerName: userData.centerInfo.name,
          childId: campConfig.childId,
          activityId: selectedActivity.activityId,
          registerInfo: {
            name: campConfig.childName,
            email: userId,
            ageGroup: campConfig.ageGroup,
            days: campConfig.days,
          },
          paymentInfo: paymentInfo,
        },
        jwtToken
      );

      switch (res.status) {
        case 200:
          showNotification(
            "success",
            t("registration.registeredTitle"),
            t("registration.registeredMessage"),
            () => {
              history.replace({
                pathname: routes.USER_ACCOUNT,
                search: location.search,
              });
            }
          );
          break;

        case 208:
          handle208(res.data.type, res.data.message);
          break;

        case 403:
          showNotification("expired");
          break;

        default:
          throw new Error("system-error");
      }
    } catch (e) {
      if (e.message === "system-error") {
        showNotification("system");
      } else {
        showNotification("network");
      }
    } finally {
      showLoading(false);
    }
  };

  // handle waiting
  const handleWaiting = async () => {
    try {
      showLoading(true);
      const res = await request.post(
        api.waiting,
        {
          userId: userId,
          superId: superId,
          centerId: centerId,
          childId: campConfig.childId,
          activityId: selectedActivity.activityId,
          waitingInfo: {
            name: campConfig.childName,
            email: userId,
            ageGroup: campConfig.ageGroup,
            days: campConfig.days,
          },
        },
        jwtToken
      );

      switch (res.status) {
        case 200:
          showNotification(
            "success",
            t("registration.waitingTitle"),
            t("registration.waitingMessage"),
            () => {
              history.replace({
                pathname: routes.USER_ACCOUNT,
                search: location.search,
              });
            }
          );
          break;

        case 208:
          handle208(res.data.type, res.data.message);
          break;

        case 403:
          showNotification("expired");
          break;

        default:
          throw new Error("system-error");
      }
    } catch (e) {
      if (e.message === "system-error") {
        showNotification("system");
      } else {
        showNotification("network");
      }
    } finally {
      showLoading(false);
    }
  };

  // confirmation page
  return (
    <div className="confirmation">
      <div className="confirmation__title">
        {t("registration.confirmationTitle")}
      </div>
      <div className="confirmation__content">
        <div className="confirmation__content__title">
          <div className="confirmation__content__title__registerFor">
            {t("registration.registerFor") + campConfig.childName}
          </div>
          <div className="confirmation__content__title__siteName">
            {userData.centerInfo.name}
          </div>
        </div>
        <div className="confirmation__content__activity-details">
          <div className="confirmation__content__activity-details__section1">
            <div className="confirmation__content__activity-details__section1__name">
              {selectedActivity.info.name}
            </div>
            <div className="confirmation__content__activity-details__section1__ageGroup">
              {campConfig.ageGroup}
            </div>
          </div>

          <div className="confirmation__content__activity-details__section2">
            {campConfig.days.length > 0 ? (
              <div className="confirmation__content__activity-details__section2__selectedDates">
                {mappingDays(
                  selectedActivity.fromDate,
                  selectedActivity.toDate,
                  campConfig.days
                )}
              </div>
            ) : (
              <div className="confirmation__content__activity-details__section2__date">
                <img src={calendarWhite} alt="calendar" />
                {formatDateMonth(selectedActivity.fromDate)} -{" "}
                {formatDateMonth(selectedActivity.toDate)}
              </div>
            )}
            <div className="confirmation__content__activity-details__section2__time">
              <img src={clockWhite} alt="clock" />
              {`${selectedActivity.info.startTime} - ${selectedActivity.info.endTime}`}
            </div>
          </div>
          <div className="confirmation__content__activity-details__section3">
            <div className="confirmation__content__activity-details__section3__sessionFee">
              <label className="confirmationLabels">
                {t("registration.sessionFee")}
              </label>
              ${campConfig.total}
            </div>
            <div className="confirmation__content__activity-details__section3__transactionFee">
              <label className="confirmationLabels">
                {t("registration.transactionFee")}
              </label>
              ${transFee}
            </div>
            <div className="confirmation__content__activity-details__section3__total">
              <label className="confirmationLabels">
                {t("registration.total")}
              </label>
              ${grandTotal}
            </div>
          </div>
        </div>
      </div>

      {aStatus === "status-join" && campConfig.total > 0 && (
        <div className="confirmation__payment">
          <div className="confirmation__payment__title">
            {t("registration.paymentMethod")}
          </div>
          {familyPayment ? (
            <div className="confirmation__payment__content">
              <div className="confirmation__payment__content__name">
                <label>{t("registration.name")}</label>
                <p>{familyPayment.name}</p>
              </div>
              <div className="confirmation__payment__content__cardNumber">
                <label>{`${familyPayment.brand} ${t(
                  "registration.cardNumber"
                )}`}</label>
                <p className="confirmation__payment__content__cardNumber__mask">
                  {familyPayment.card}
                </p>
              </div>
              <div className="confirmation__payment__content__expiry">
                <label>{t("registration.expiry")}</label>
                <p>{familyPayment.expiry}</p>
              </div>
            </div>
          ) : (
            <>
              <PaymentForm
                name={cardholderName}
                setName={(n) => {
                  setValidName(true);
                  setCardholderName(n);
                }}
                stripeObject={stripeObject}
                creditCardRef={creditCardRef}
                validation={{
                  valid: validName,
                  message: t("familyProfile.nameIsRequired"),
                }}
              />
              <div
                className="save-card"
                onClick={() => {
                  setChecked(!checked);
                }}
              >
                <input type="checkbox" checked={checked} readOnly />
                <div className="save-card__message">
                  {t("registration.saveCard")}
                </div>
              </div>
            </>
          )}
        </div>
      )}
      <div className="confirmation__cta">
        <GreyButton
          text={t("registration.confirmPay")}
          className="cancel-button"
          onClick={() => {
            setShowConfirmation(false);
          }}
        >
          {t("registration.cancel")}
        </GreyButton>
        {aStatus === "status-join" ? (
          <BlueButton
            className="confirmPay-button"
            onClick={handleRegistration}
          >
            {t("registration.confirmPay")}
          </BlueButton>
        ) : (
          <YellowButton className="confirmPay-button" onClick={handleWaiting}>
            {t("registration.addToWaitingList")}
          </YellowButton>
        )}
      </div>
    </div>
  );
}

export default Confirmation;
