const userAccount = {
  heading: "Hi, Parent Name",
  familyInformation: "Family Information",
  familyInformationNote:
    "*Please fill the family information and add your child later.",
  fillFamilyForm: "Fill Family Form",
  editFamilyForm: "Edit Family Form",
  childInformation: "Child Information & Registration Records",
  addChild: "Add Child",
  guardian1: "Guardian 1",
  guardian2: "Guardian 2",
  emergencyContact: "Emergency Contact",
  paymentMethod: "Payment Method",
  name: "Name",
  phone: "Phone",
  email: "Email",
  cellPhone: "Cell Phone",
  creditCard: "Credit Card",
  expiry: "Expiry",
  childName: "Child Name",
  firstName: "First Name",
  lastName: "Last Name",
  editChildForm: "Edit Child Form",
  completeChildForm: "Complete Child Form",
  registrationHistory: "Registration History",
  registrationInstruction:
    "*Please fill child information to register activities.",
  childModalHeader: "Child Information",
  childModalSubHeader:
    "*You cannot remove any child once created. Please make sure your child name is correct.",
  cancel: "Cancel",
  submit: "Submit",
  addedTitle: "Added",
  addedMessage:
    "Please ensure that the child's information is completed prior to registration.",
};
export default userAccount;
