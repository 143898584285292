import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// auth and app context
import { AuthProvider } from "./context/AuthContext";
import { AppContext } from "./context/AppContext";

// app level routing
import AppRouting from "./context/routing";
import routes from "./context/routes";

// global loading and notification
import Loading from "./components/loading/Loading";
import Notification from "./components/notification/Notification";

// 404 page in case of invalid url
import Page404 from "./views/login/Page404";

// utils
import { idDecrypt } from "./utils/idDecoder";

// ----------- app entry -------------
function App() {
  const { i18n } = useTranslation(); // switching language

  // global loading and pop-up notification
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(null);

  // global context data
  const [entryPoint, setEntryPoint] = useState(null); // super / center / payment
  const [superId, setSuperId] = useState(null); // valid string when entry point is super
  const [centerId, setCenterId] = useState(null); // valid string when entry point is super or center
  const [paymentInfo, setPaymentInfo] = useState({}); // valid object entry point is payment
  const [userData, setUserData] = useState({}); // user data object

  // initial route
  const [initRoute, setInitRoute] = useState(null);

  // show loading
  const showLoading = (
    show // true or false
  ) => {
    setLoading(show); // false to dismiss!
  };

  // show notification
  const showNotification = (type, title, message, callback) => {
    if (!type) {
      setNotification(null);
    } else {
      setNotification({
        type, // warning / system / network / success
        title, // notification title
        message, // notification message
        callback, // null or callback function upon clicking on OK
      });
    }
  };

  // update center id
  const updateCenterId = (id) => {
    sessionStorage.setItem("_cId", id);
    setCenterId(id);
  };

  // update user data
  const updateUserData = (data) => {
    // updated user data
    const updatedData = {
      ...userData,
      ...data,
    };
    sessionStorage.setItem("_uData", JSON.stringify(updatedData));
    setUserData(updatedData);
  };

  // move to right route
  const moveToRoute = (route) => {
    // restore the session
    const uData = JSON.parse(sessionStorage.getItem("_uData"));
    setUserData(uData || {});

    if (window.location.pathname !== "/") {
      setInitRoute(window.location.pathname); // keep current route
    } else {
      setInitRoute(route);
    }
  };

  // app language and initial route
  useEffect(() => {
    // app language
    const lan = localStorage.getItem("_language") || "en";
    if (lan) {
      i18n.changeLanguage(lan); // change language
    }

    // entry url query parameters
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.size === 0) {
      setInitRoute("404"); // invalid url
      return;
    }

    // decode id
    const id = urlParams.get("id");
    const decodedId = idDecrypt(process.env.REACT_APP_SALT, id); // null in case of invalid id
    if (!decodedId) {
      setInitRoute("404"); // invalid url
      return;
    }

    // entry point
    const TYPES = ["super", "center", "payment"];
    const type = urlParams.get("type");
    if (!TYPES.includes(type)) {
      setInitRoute("404"); // invalid url
      return;
    }

    // setup initial route
    switch (type) {
      case "super":
        setEntryPoint("super");
        setSuperId(decodedId);
        sessionStorage.setItem("_sId", decodedId);
        const cId = sessionStorage.getItem("_cId");
        if (cId) {
          setCenterId(cId);
        } else {
          setCenterId(null);
          sessionStorage.setItem("_cId", null);
        }
        moveToRoute(routes.CENTER_LIST);
        break;

      case "center":
        setEntryPoint("center");
        setCenterId(decodedId);
        sessionStorage.setItem("_cId", decodedId);
        setSuperId(null);
        sessionStorage.setItem("_sId", null);
        moveToRoute(routes.ACTIVITY_LIST);
        break;

      case "payment":
        const ids = decodedId.split("#");
        if (ids.length !== 3) {
          setInitRoute("404"); // invalid url
          break;
        }
        setEntryPoint("payment");
        setPaymentInfo({
          centerId: ids[0],
          childId: ids[1],
          activityId: ids[2],
        });
        moveToRoute(routes.PAYMENT_INFO);
        break;

      default:
        setInitRoute("404"); // invalid url
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // app rendering
  return (
    <AuthProvider>
      {!initRoute ? (
        <></>
      ) : initRoute === "404" ? (
        <Page404 />
      ) : (
        <AppContext.Provider
          value={{
            entryPoint,
            superId,
            centerId,
            paymentInfo,
            userData,
            showLoading,
            showNotification,
            updateCenterId,
            updateUserData,
          }}
        >
          <div className="app">
            <AppRouting initRoute={initRoute} />
            {loading && <Loading />}
            {notification && <Notification notification={notification} />}
          </div>
        </AppContext.Provider>
      )}
    </AuthProvider>
  );
}

export default App;
