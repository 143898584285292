import Resizer from "react-image-file-resizer";

// compress the file size
export const resizeFile = (file, id) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file, // path of original file
      id === "upload-guardian-avatar" ? 340 : 200, // new image max width (ratio is preserved)
      id === "upload-guardian-avatar" ? 340 : 200, // new image max height (ratio is preserved)
      "JPEG", // compress format ( JPEG, PNG or WEBP)
      80, // for the JPEG compression (0 to 100, 100 meaning no compress is needed)
      0, // the degree of clockwise rotation (0, 90, 180, 270, 360)
      (uri) => {
        resolve(uri);
      }, // callBack function of the resized image URI (resized image's base64 format)
      "file" // output type of the resized image (base64, blob or file)
    );
  });

// extract file name and extension
export const getFileDetails = (file) => {
  const fileDetails = {
    name: "",
    type: "",
    extension: "",
  };

  if (file && file.name) {
    const nameSplit = file.name.split(".");

    // get mime type
    fileDetails.type = file.type;

    // get file extension by getting the last element of the nameSplit
    fileDetails.extension = nameSplit[nameSplit.length - 1];

    // get file name
    fileDetails.name = file.name.slice(
      0,
      file.name.length - fileDetails.extension.length - 1
    );
  }

  return fileDetails;
};
