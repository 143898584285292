import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// context
import { useAuth } from "../../context/AuthContext";
import { useAppContext } from "../../context/AppContext";

// backend service
import api from "../../services/api";
import request from "../../services/Request";

// components
import InfoCard from "./InfoCard";

// icons
import searchIcon from "../../assets/icons/searchIcon.png";
import minusIcon from "../../assets/icons/minus-icon.png";
import plusIcon from "../../assets/icons/plus-icon.png";
import noDataIcon from "../../assets/icons/no-data-icon.png";

// scss
import "../../scss/views/CenterList/CenterList.scss";

// center list page
function CenterList() {
  const { t } = useTranslation(); // translation

  const { jwtToken } = useAuth();
  const { superId, showLoading, showNotification } = useAppContext();

  const [centerListData, setCenterListData] = useState([]);
  const [centerList, setCenterList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isFold, setIsFold] = useState([]);

  // handle search keyword
  const handleInput = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setInputValue(searchValue);

    //reset fold status
    setIsFold([]);

    // Filter centers based on search value
    const filteredCenters = centerListData.reduce((acc, cityData) => {
      const filteredCityCenters = cityData.centers.filter((center) =>
        center.name.toLowerCase().includes(searchValue)
      );

      if (filteredCityCenters.length > 0) {
        acc.push({
          city: cityData.city,
          centers: filteredCityCenters,
        });
      }

      return acc;
    }, []);

    setCenterList(filteredCenters);
  };

  // fold specific city
  const handleFold = (item) => {
    const newIsFold = [...isFold];
    if (!item.city) {
      return;
    }
    if (newIsFold.includes(item.city)) {
      newIsFold.splice(newIsFold.indexOf(item.city), 1);
    } else {
      newIsFold.push(item.city);
    }
    setIsFold(newIsFold);
  };

  const Sites = () => {
    return (
      <div className="centers">
        {centerList.length > 0 ? (
          centerList.map((item, index) => {
            return (
              <div key={`index-${index}`} className="center">
                <div className="center__name">
                  <label>{item.city}</label>
                  <div className="fold-icon" onClick={() => handleFold(item)}>
                    <img
                      src={isFold.includes(item.city) ? plusIcon : minusIcon}
                      alt=""
                    />
                  </div>
                </div>
                <div
                  className={`center-group ${
                    isFold.includes(item.city) ? "hide-group" : ""
                  }`}
                >
                  {item.centers &&
                    item.centers.length > 0 &&
                    item.centers.map((dataItem) => {
                      return <InfoCard key={dataItem.id} info={dataItem} />;
                    })}
                </div>
              </div>
            );
          })
        ) : (
          <div className="no-data-container">
            <img src={noDataIcon} alt="" />
            {t("centerList.noResult")}
          </div>
        )}
      </div>
    );
  };

  // load data
  useEffect(() => {
    const loadData = async () => {
      try {
        showLoading(true);
        const res = await request.get(
          api.centerList,
          {
            superId: superId,
          },
          jwtToken
        );

        switch (res.status) {
          case 200:
            setCenterListData(res.data);
            setCenterList(res.data);
            break;

          case 403:
            showNotification("expired");
            break;

          default:
            throw new Error("system-error");
        }
      } catch (e) {
        if (e.message === "system-error") {
          showNotification("system");
        } else {
          showNotification("network");
        }
      } finally {
        showLoading(false);
      }
    };

    // load center list
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // render center list page
  return (
    <div className="center-list">
      <div className="header">
        <div className="search-container">
          <img className="search-icon" src={searchIcon} alt="center-search" />
          <input
            type="text"
            value={inputValue}
            onChange={handleInput}
            placeholder={t("centerList.searchSiteName")}
          />
        </div>
      </div>
      <Sites />
    </div>
  );
}

export default CenterList;
