import React from "react";
import { useTranslation } from "react-i18next";

// utils
import { formatDateMonth, getActiveDates } from "../../utils/tools";

// assets
import successIcon from "../../assets/icons/success-icon.png";
import calendarIcon from "../../assets/icons/calendar-black.png";
import clockIcon from "../../assets/icons/clock-black.png";

// scss
import "../../scss/views/PaymentInfo/PaymentSuccessfullyPage.scss";

/**
 * @return {JSX.Element}
 * @constructor
 */
function PaymentSuccessfullyPage({ details, total }) {
  const { t } = useTranslation(); // translation

  const DisplaySelectedDates = ({ selectedDates }) => {
    let sDateString = "";
    // make selectedDates into a hash map
    const dMap = {};
    selectedDates
      .split(",")
      .map((d) => d.trim())
      .forEach((d) => {
        const dSplit = d.split(" ");
        if (dMap[dSplit[0]]) {
          dMap[dSplit[0]] = [...dMap[dSplit[0]], dSplit[1]];
        } else {
          dMap[dSplit[0]] = [dSplit[1]];
        }
      });

    // make selected dates into this format [xmonth xdate, xdate]
    if (Object.keys(dMap).length > 0) {
      Object.keys(dMap).forEach((dm) => {
        sDateString = sDateString + ` ${dm} `; // insert month
        let count = 1;
        let dmLength = dMap[dm].length;

        dMap[dm].forEach((d) => {
          // insert date
          if (dmLength === count) {
            sDateString = sDateString + d;
          } else {
            sDateString = sDateString + ` ${d}, `;
          }

          count++;
        });
      });
    }

    // return this kind of format [Selected Date: Jul 24, 26]
    return `${t("paymentInfo.selectedDays")}: ${sDateString}`;
  };

  return (
    <div className="payment-successfully-page">
      <div className="payment-successfully-page__header">
        <div>
          <img src={successIcon} alt="success" />
          <label>{t("paymentInfo.success")}</label>
        </div>
      </div>
      <div className="info">
        <div className="details">
          <h3>{details?.activity?.name}</h3>
          <label>{details?.child?.ageGroup}</label>
          <div className="time">
            <div>
              <img src={calendarIcon} alt={details?.activity?.name} />
              {`${formatDateMonth(
                details.activity.fromDate
              )} - ${formatDateMonth(details.activity.toDate)}`}
            </div>
            <div>
              <img src={clockIcon} alt={details?.activity?.name} />
              {`${details?.activity?.startTime} - ${details?.activity?.endTime}`}
            </div>
          </div>
        </div>
        <div className="rates">
          {details.activity.dailySession && (
            <DisplaySelectedDates
              selectedDates={getActiveDates(
                details?.child?.days,
                details?.activity?.fromDate,
                details?.activity?.toDate
              )}
            />
          )}
        </div>
      </div>
      <div className="total">
        {t("paymentInfo.total")}: <span>{`$${total}`}</span>
      </div>
    </div>
  );
}

export default PaymentSuccessfullyPage;
