import React from "react";
import { useTranslation } from "react-i18next";

// scss
import "../../scss/components/input-fields/Input.scss";

/**
 * The Input function is a component in JavaScript that renders an input field with a label,
 * placeholder, and validation message.
 * @returns A functional component named Input is being returned. It takes in several props such as id,
 * label, type, value, placeHolder, setter, and validation. It renders a label and an input element
 * with the given props and an onChange event that updates the value using the setter function. It also
 * renders a validation message if the validation object is not valid. The component uses the
 * useTranslation hook from the
 */

const Input = ({
  id,
  label,
  type,
  value,
  accept,
  placeHolder,
  setter,
  validation,
  className = "",
  disabled = false,
}) => {
  const { t } = useTranslation(); //locale translation

  return (
    <div className={`custom-input ${className}`}>
      <label htmlFor={id}>{label}</label>
      <input
        name={id}
        id={id}
        type={type}
        accept={accept}
        value={value}
        placeholder={placeHolder}
        disabled={disabled}
        onChange={(e) => setter(e.target.value, id)}
      />
      {validation && !validation.valid && (
        <div className="validation-container">
          <span>{t(validation.message)}</span>
        </div>
      )}
    </div>
  );
};

export default Input;
