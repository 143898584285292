const login = {
  registration: "Camp & Activity Registration",
  signIn: "Sign In or Register",
  signInDes: "Please enter your email to sign in or register a new account",
  email: "Email",
  emailPlaceholder: "Enter your email",
  invalidEmail: "Invalid email",
  password: "Password",
  forgotPassword: "Forgot Password",
  forgotPasswordTitle: "Check Verification Code",
  forgotPasswordMessage:
    "Please check your email and input the verification code.",
  resendCode: "Resend Code",
  resendCodeTitle: "Code Sent",
  resendCodeMessage: "Please check your email for the verification code.",
  passwordDes: "Please enter your password",
  passwordPlaceholder: "Enter your password",
  wrongPassword: "Password doesn't match",
  verificationCode: "Verification Code",
  codeDes: "Please enter 6-digit code sent to your email",
  codePlaceholder: "Enter 6-digit code",
  invalidCode: "Invalid verification code",
  wrongCode: "Verification code doesn't match",
  setupPassword: "Setup Password",
  newPassword: "New Password",
  confirmPassword: "Confirm Password",
  noPassword: "Please enter your password",
  mismatchedPassword: "Password doesn't match",
  backToEmail: "< Back To Email",
  page404: "Page Not Found",
};

export default login;
