import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// context and routes
import { useAuth } from "../../context/AuthContext";
import { useAppContext } from "../../context/AppContext";
import routes from "../../context/routes";

// backend service
import api from "../../services/api";
import request from "../../services/Request";

// common components
import BackLine from "../../components/backLine/BackLine";
import BlueButton from "../../components/buttons/BlueButton";
import GreyButton from "../../components/buttons/GreyButton";

// component
import ChildDetailsForm from "../../views/child-profile/ChildForms/ChildDetailsForm";
import PickUpListForm from "../../views/child-profile/ChildForms/PickUpListForm";
import ConsentForm from "../../views/child-profile/ChildForms/ConsentForm";

// utils
import { validate } from "../../utils/formValidation";
import { deepClone } from "../../utils/tools";
import validationTemplate from "../../utils/definitions/ValidationTemplateChild";
import childDataTemplate from "../../utils/definitions/ChildDataTemplate";

// default avatar
import imgDefault from "../../assets/imgs/defaultAvatar.png";

// styles
import "../../scss/views/ChildProfile/ChildForm.scss";

const defaultToggleValues = {
  allergies: false,
  disabilities: false,
  medical: false,
  specialNeeds: false,
};

// child profile page
function ChildProfile() {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const { userId, jwtToken } = useAuth();
  const { centerId, showLoading, showNotification, userData } = useAppContext();

  const selectedChild = userData.selectedChild;
  const [childDetails, setChildDetails] = useState({
    ...childDataTemplate,
  });
  const [toggleValues, setToggleValues] = useState({ ...defaultToggleValues });
  const [validatePhotoUpload, setValidatePhotoUpload] = useState(true);

  const [formValidation, setFormValidation] = useState({
    ...validationTemplate,
  });

  // load child data
  useEffect(() => {
    const loadData = async () => {
      try {
        showLoading(true);
        const res = await request.get(
          api.childProfile,
          {
            childId: selectedChild.childId,
          },
          jwtToken
        );

        switch (res.status) {
          case 200:
            // workaround for missing pickupList from older data
            if (!res.data.pickupList) {
              res.data.pickupList = [];
            }

            setChildDetails(res.data);

            //set form validation
            const tempValidation = deepClone(validationTemplate);

            // create validation for pickupList on component mount
            if (childDetails.pickupList) {
              res.data.pickupList.forEach((guardian, index) => {
                tempValidation[`guardian-name-${index}`] = {
                  rules: ["required"],
                  message: "",
                  valid: true,
                };
                tempValidation[`guardian-phone-${index}`] = {
                  rules: ["required", "phoneNumberFormat"],
                  message: "",
                  valid: true,
                };
              });
            }
            setFormValidation(tempValidation);

            setToggleValues({
              allergies: Boolean(res.data.allergies),
              disabilities: Boolean(res.data.disabilities),
              medical: Boolean(res.data.medical),
              specialNeeds: Boolean(res.data.specialNeeds),
            });
            break;

          case 403:
            showNotification("expired");
            break;

          default:
            throw new Error("system-error");
        }
      } catch (e) {
        if (e.message === "system-error") {
          showNotification("system");
        } else {
          showNotification("network");
        }
      } finally {
        showLoading(false);
      }
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // back to user account
  const goBack = () => {
    history.push({ pathname: routes.USER_ACCOUNT, search: location.search });
  };
  const requiredProperties = [
    "childName",
    "birthday",
    "gender",
    "address",
    "city",
    "province",
    "postCode",
    "doctorName",
    "careCard",
    "allergies",
    "disabilities",
    "medical",
    "specialNeeds",
    "swimming",
  ];

  // handle form submission
  const submitChildInformation = async () => {
    if (document.getElementById("child-avatar").src === imgDefault) {
      setValidatePhotoUpload(false);
      return;
    }
    // set validation values
    requiredProperties.forEach((property) => {
      formValidation[property].value = childDetails[property];
    });

    // set validation values for pickupList
    childDetails.pickupList.forEach((guardian, index) => {
      const guardianName = `guardian-name-${index}`;
      const guardianPhone = `guardian-phone-${index}`;
      formValidation[guardianName].value = guardian.name;
      formValidation[guardianPhone].value = guardian.phone;
    });

    // set validation values for consents
    childDetails.consent.split("").forEach((qAnswer, index) => {
      const questionKey = `consent_${index}`;
      formValidation[questionKey].value = qAnswer === "3" ? "" : qAnswer;
    });

    // validate photo upload
    if (!validatePhotoUpload) {
      setValidatePhotoUpload(false);
      return;
    }
    // validate other fields
    const tempValidate = validate(formValidation);
    if (!tempValidate.valid) {
      setFormValidation({ ...tempValidate });
      return;
    }

    // send to backend
    try {
      showLoading(true);

      const info = {};
      requiredProperties.forEach((property) => {
        info[property] = childDetails[property] || "";
      });

      const res = await request.put(
        api.childProfile,
        {
          userId: userId,
          childId: selectedChild.childId,
          info: info,
          pickupList: [...childDetails.pickupList],
          consent: childDetails.consent,
          formIncomplete: selectedChild.formIncomplete,
        },
        jwtToken
      );

      switch (res.status) {
        case 200:
          showNotification(
            "success",
            t("childProfile.savedTitle"),
            t("childProfile.savedMessage"),
            goBack
          );
          break;

        case 403:
          showNotification("expired");
          break;

        default:
          throw new Error("system-error");
      }
    } catch (e) {
      if (e.message === "system-error") {
        showNotification("system");
      } else {
        showNotification("network");
      }
    } finally {
      showLoading(false);
    }
  };

  // child profile page
  return (
    <div className="child-information">
      <BackLine route={routes.USER_ACCOUNT} />
      <div className="child-information__content">
        <ChildDetailsForm
          centerId={centerId}
          childSelected={selectedChild}
          childDetails={childDetails}
          formValidation={formValidation}
          toggleValues={toggleValues}
          setFormValidation={setFormValidation}
          setChildDetails={setChildDetails}
          setToggleValues={setToggleValues}
          showLoading={showLoading}
          showNotification={showNotification}
          validatePhotoUpload={validatePhotoUpload}
          setValidatePhotoUpload={setValidatePhotoUpload}
          jwtToken={jwtToken}
        />
        <PickUpListForm
          childDetails={childDetails}
          formValidation={formValidation}
          setFormValidation={setFormValidation}
          setChildDetails={setChildDetails}
        />
        <ConsentForm
          childDetails={childDetails}
          formValidation={formValidation}
          setFormValidation={setFormValidation}
          setChildDetails={setChildDetails}
        />
      </div>
      <div className="child-form-footer">
        <GreyButton
          className="childFormCancelButton"
          onClick={() =>
            history.push({
              pathname: routes.USER_ACCOUNT,
              search: location.search,
            })
          }
        >
          {t("childProfile.cancel")}
        </GreyButton>
        <BlueButton
          className="childFormSubmitButton"
          onClick={submitChildInformation}
        >
          {t("childProfile.submit")}
        </BlueButton>
      </div>
    </div>
  );
}
export default ChildProfile;
