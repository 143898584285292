import axios from "axios";

// axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 10 * 1000,
  validateStatus: function (status) {
    return status >= 200 && status <= 500; // user to handle response status range 200 ~ 500 !!
  },
});

// axios request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,
      "x-api-key": process.env.REACT_APP_API_KEY,
    };

    // ATTENTION: no jwt token for requests sending directly to S3 server!!
    if (config.jwtToken) {
      config.headers = {
        ...config.headers,
        Authorization: config.jwtToken, // add JWT-Token
      };
    }

    // ATTENTION: Android device needs to config content type for requests sending directly to S3 server!!
    if (config.s3Upload) {
      config.headers = {
        ...config.headers,
        "Content-Type": "multipart/form-data", // content type set to multipart/form-data
      };
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// backend request
const request = {
  get: (relativeUrl, urlParams, jwtToken) => {
    return axiosInstance({
      method: "get",
      url: relativeUrl,
      params: urlParams,
      jwtToken: jwtToken,
    });
  },
  post: (relativeUrl, requestBody, jwtToken, s3Upload) => {
    return axiosInstance({
      method: "post",
      url: relativeUrl,
      data: requestBody,
      jwtToken: jwtToken, // no jwt token for uploading file to s3 directly
      s3Upload: s3Upload, // this flag is only for uploading file to s3 directly
    });
  },
  put: (relativeUrl, requestBody, jwtToken) => {
    return axiosInstance({
      method: "put",
      url: relativeUrl,
      data: requestBody,
      jwtToken: jwtToken,
    });
  },
  delete: (relativeUrl, requestBody, jwtToken) => {
    return axiosInstance({
      method: "delete",
      url: relativeUrl,
      data: requestBody,
      jwtToken: jwtToken,
    });
  },
};

export default request;
