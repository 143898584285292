import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// utils
import { validate } from "../../utils/formValidation";

// components
import CenterModal from "./CenterModal";
import BlueButton from "../buttons/BlueButton";
import GreyButton from "../buttons/GreyButton";
import Input from "../input-fields/Input";

// style and asset
import "../../scss/components/modals/AddChildModal.scss";
import ChildAvatar from "../../assets/imgs/childAvatar.png";

const validationTemplate = {
  firstName: {
    rules: ["required"],
    message: "",
    valid: true,
  },
  lastName: {
    rules: ["required"],
    message: "",
    valid: true,
  },
  valid: null,
};

// add child modal
function ChildModal({ setDisplayAddModal, addChild }) {
  const { t } = useTranslation();

  const [childInfo, setChildInfo] = useState({ firstName: "", lastName: "" });
  const [formValidation, setFormValidation] = useState(validationTemplate); // form validation state

  // cancel modal
  const cancelModal = () => {
    setDisplayAddModal(false);
  };

  // handle input change
  const handleInputChange = (value, id) => {
    const tempValidation = { ...formValidation };

    // reset formValidation
    tempValidation[id].message = "";
    tempValidation[id].valid = true;
    setFormValidation({ ...tempValidation });

    // set new value
    const tempChildInfo = { ...childInfo };
    tempChildInfo[id] = value;
    setChildInfo(tempChildInfo);
  };

  // add one child
  const handleModalSubmit = () => {
    // reset validation
    setFormValidation(validationTemplate);

    //set validation values

    formValidation.firstName.value = childInfo.firstName;
    formValidation.lastName.value = childInfo.lastName;

    const tempValidate = validate(formValidation);
    if (!tempValidate.valid) {
      setFormValidation({
        ...tempValidate,
      });
      return;
    }

    // add child
    addChild(childInfo.firstName, childInfo.lastName);
  };

  return (
    <CenterModal cancelModal={cancelModal} shouldDisplay={true}>
      <div className="child-modal">
        <div className="child-modal-header">
          <img src={ChildAvatar} alt="child-avatar" />
          <h3>{t("userAccount.childModalHeader")}</h3>
          <span>{t("userAccount.childModalSubHeader")}</span>
        </div>

        <Input
          id="firstName"
          label={t("userAccount.childName")}
          placeHolder={t("userAccount.firstName")}
          value={childInfo.firstName}
          setter={handleInputChange}
          validation={formValidation.firstName}
        />
        <Input
          id="lastName"
          placeHolder={t("userAccount.lastName")}
          value={childInfo.lastName}
          setter={handleInputChange}
          validation={formValidation.lastName}
        />

        <div className="child-modal-cta">
          <GreyButton className="child-modal-cta__button" onClick={cancelModal}>
            {t("userAccount.cancel")}
          </GreyButton>
          <BlueButton
            className="child-modal-cta__button"
            onClick={handleModalSubmit}
          >
            {t("userAccount.submit")}
          </BlueButton>
        </div>
      </div>
    </CenterModal>
  );
}

export default ChildModal;
