import React from "react";
import { useHistory, useLocation } from "react-router-dom";

// context and routes
import { useAppContext } from "../../context/AppContext";
import routes from "../../context/routes";

// style and asset
import "../../scss/views/CenterList/InfoCard.scss";
import LocationIcon from "../../assets/icons/locationIcon.png";

/**
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */

const InfoCard = (props) => {
  const location = useLocation();
  const history = useHistory();
  const { updateCenterId } = useAppContext();

  // redirect to center activity list
  const redirect = () => {
    updateCenterId(props.info.id);
    history.push({ pathname: routes.ACTIVITY_LIST, search: location.search });
  };

  return (
    <div className="info-card" onClick={redirect}>
      <div className="short-name">{props.info.name}</div>
      <div className="address">
        <img src={LocationIcon} alt="" />
        <span>{props.info.address}</span>
      </div>
    </div>
  );
};

export default InfoCard;
