// app routes
const LOGIN_PAGE = "/login";

const USER_ACCOUNT = "/user-account";
const FAMILY_PROFILE = "/family-profile";
const CHILD_PROFILE = "/child-profile";
const REGISTERED_ACTIVITIES = "/registered-activities";

const CENTER_LIST = "/center-list";
const ACTIVITY_LIST = "/activity-list";
const ACTIVITY_REGISTRATION = "/activity-registration";

const PAYMENT_INFO = "/payment-info";

const routes = {
  LOGIN_PAGE,
  USER_ACCOUNT,
  FAMILY_PROFILE,
  CHILD_PROFILE,
  REGISTERED_ACTIVITIES,
  CENTER_LIST,
  ACTIVITY_LIST,
  ACTIVITY_REGISTRATION,
  PAYMENT_INFO,
};

export default routes;
