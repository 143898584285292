/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useTranslation } from "react-i18next";

// common components
import Input from "../../../components/input-fields/Input";
import WhiteButton from "../../../components/buttons/WhiteButton";

// utils
import { deepClone } from "../../../utils/tools";

// assets
import deleteIcon from "../../../assets/icons/deleteIcon.png";

// scss
import "../../../scss/views/ChildProfile/childForms/PickUpListForm.scss";

function PickUpListForm({
  childDetails,
  formValidation,
  setFormValidation,
  setChildDetails,
}) {
  const { t } = useTranslation(); // translation

  const handleInputChange = (value, id) => {
    const tempValidation = { ...formValidation };

    // reset formValidation
    tempValidation[id].message = "";
    tempValidation[id].valid = true;
    setFormValidation({ ...tempValidation });

    // set new value
    const tempChildDetails = deepClone(childDetails); // deep copy
    const idSplit = id.replace("guardian-", "").trim().split("-"); // name-$index, phone-$index

    // get guardian to be updated then update its value
    const updateGuardian = tempChildDetails.pickupList[idSplit[1]];
    updateGuardian[idSplit[0]] = value;

    setChildDetails(tempChildDetails);
  };

  const addPickupList = () => {
    // add new guardian to the child details pickup list
    const tempChildDetails = deepClone(childDetails); // deep copy

    tempChildDetails.pickupList.push({ name: "", phone: "" }); // push blank guardian

    setChildDetails(tempChildDetails); // child details

    // add validation for  new guardian
    const tempValidation = { ...formValidation };

    tempValidation[`guardian-name-${tempChildDetails.pickupList.length - 1}`] =
      {
        rules: ["required"],
        message: "",
        valid: true,
      };
    tempValidation[`guardian-phone-${tempChildDetails.pickupList.length - 1}`] =
      {
        rules: ["required", "phoneNumberFormat"],
        message: "",
        valid: true,
      };

    setFormValidation({ ...tempValidation }); // form validation
  };

  const deletePickupItem = (index) => {
    // remove guardian
    const tempChildDetails = deepClone(childDetails); // deep copy
    tempChildDetails.pickupList.splice(index, 1); // remove the guardian by index
    setChildDetails(tempChildDetails);

    // remove validation for deleted guardian
    const tempValidation = { ...formValidation };
    Object.keys(tempValidation).forEach((validationKey) => {
      if (validationKey.includes("guardian")) {
        delete tempValidation[validationKey];
      }
    });

    // create new validation for guardians
    tempChildDetails.pickupList.forEach((guardian, index) => {
      tempValidation[`guardian-name-${index}`] = {
        rules: ["required"],
        message: "",
        valid: true,
      };
      tempValidation[`guardian-phone-${index}`] = {
        rules: ["required", "phoneNumberFormat"],
        message: "",
        valid: true,
      };
    });

    setFormValidation({ ...tempValidation });
  };

  return (
    <div className="pick-up-list-form">
      <h3> {t("childProfile.pickupList")}</h3>
      <div className="guardians">
        {childDetails.pickupList?.map((guardian, index) => {
          const phoneId = `guardian-phone-${index}`;
          const nameId = `guardian-name-${index}`;
          return (
            <div key={`guardian-pickup-${index}`} className="guardian">
              <Input
                id={nameId}
                label={`${t("childProfile.name")}*`}
                type="text"
                value={guardian.name}
                placeHolder={""}
                setter={handleInputChange}
                validation={formValidation[`${nameId}`]}
              />
              <Input
                id={phoneId}
                label={`${t("childProfile.phoneNumber")}*`}
                type="text"
                value={guardian.phone}
                placeHolder={""}
                setter={handleInputChange}
                validation={formValidation[`${phoneId}`]}
              />
              <img
                src={deleteIcon}
                alt=""
                onClick={() => deletePickupItem(index)}
              />
            </div>
          );
        })}
      </div>
      <WhiteButton className="addPickupButton" onClick={addPickupList}>
        {t("childProfile.addPickUpList")}
      </WhiteButton>
    </div>
  );
}

export default PickUpListForm;
