import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// app context
import { useAuth } from "../../context/AuthContext";
import { useAppContext } from "../../context/AppContext";

// backend service
import api from "../../services/api";
import request from "../../services/Request";

// utils
import { validVerificationCode } from "../../utils/validation";

// styling
import "../../scss/views/Login/login.scss";

// code verification page
const VerifyCode = ({ email, changeView }) => {
  const { t } = useTranslation();

  const { login } = useAuth();
  const { showNotification } = useAppContext();

  const [loading1, setLoading1] = useState(false); // loading (continue button)
  const [loading2, setLoading2] = useState(false); // loading (resend code)
  const [error, setError] = useState(null); // warning message

  const [code, setCode] = useState(""); // verification code

  // handle resend verification code
  const handleResendCode = async () => {
    if (loading1 || loading2) return;

    // resend verification code
    try {
      setLoading2(true);
      const res = await request.post(api.login, {
        email: email,
        forgotPassword: true, // this will resend the verification code
      });

      switch (res.status) {
        case 200:
          showNotification(
            "success",
            t("login.resendCodeTitle"),
            t("login.resendCodeMessage")
          );
          break;

        default:
          throw new Error("system-error");
      }
    } catch (e) {
      if (e.message === "system-error") {
        showNotification("system");
      } else {
        showNotification("network");
      }
    } finally {
      setLoading2(false);
    }
  };

  // handle verification code input
  const handleInput = (e) => {
    // clear warning message
    setError(null);

    const inputValue = e.target.value;
    // verification code with maximum 6-digits
    let numericValue = inputValue.replace(/\D/g, "");
    if (numericValue.length > 6) numericValue = numericValue.slice(0, 6);

    // update verification code
    setCode(numericValue);
  };

  // handle continue
  const handleContinue = async () => {
    if (loading1 || loading2) return;

    // validate verification code
    if (!validVerificationCode.test(code)) {
      setError(t("login.invalidCode"));
      return;
    }

    // authenticate the user
    try {
      setLoading1(true);
      const res = await request.post(api.auth, {
        type: "auth-access-code",
        info: {
          email: email,
          accessCode: code,
        },
      });

      switch (res.status) {
        case 200:
          login(res.data.userId, res.data.jwtToken);
          changeView("setup-password");
          break;

        case 401:
          // code not matching
          setError(t("login.wrongCode"));
          break;

        default:
          throw new Error("system-error");
      }
    } catch (e) {
      if (e.message === "system-error") {
        showNotification("system");
      } else {
        showNotification("network");
      }
    } finally {
      setLoading1(false);
    }
  };

  // handle back button
  const handleBack = async () => {
    if (loading1 || loading2) return;

    // back to email
    changeView("email");
  };

  // render page
  return (
    <>
      <div className="title">{t("login.verificationCode")}</div>
      <div className="content">{t("login.codeDes")}</div>

      <div className="password-section">
        <div>{t("login.verificationCode")}</div>
        <div>
          {loading2 ? (
            <div className="loading" style={{ width: 20 }}></div>
          ) : (
            <div className="forgot-password" onClick={handleResendCode}>
              {t("login.resendCode")}
            </div>
          )}
        </div>
      </div>
      <input
        className="input-box"
        placeholder={t("login.codePlaceholder")}
        value={code}
        onChange={handleInput}
        disabled={loading1 || loading2}
      />
      <div className="error-message">{error}</div>

      <div className="continue-button" onClick={handleContinue}>
        {!loading1 ? t("common.next") : <div className="loading"></div>}
      </div>
      <div className="back-button" onClick={handleBack}>
        {t("login.backToEmail")}
      </div>
    </>
  );
};

export default VerifyCode;
