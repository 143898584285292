const childDataTemplate = {
  childName: "",
  birthday: "",
  gender: "Male",
  address: "",
  city: "",
  province: "AB",
  postCode: "",
  doctorName: "",
  careCard: "",
  allergies: "",
  disabilities: "",
  medical: "",
  specialNeeds: "",
  swimming: "",
  consent: "333333",
  pickupList: [],
};

export default childDataTemplate;
