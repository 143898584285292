import React from "react";
import { useTranslation } from "react-i18next";

// utils
import { formatDateMonth, getActiveDates } from "../../utils/tools";

// assets
import calendarIcon from "../../assets/icons/calendar-black.png";
import clockIcon from "../../assets/icons/clock-black.png";

// styles
import "../../scss/views/PaymentInfo/DisplayActivityInfo.scss";

const DisplaySelectedDates = ({ selectedDates }) => {
  const { t } = useTranslation();

  let sDateString = "";
  // make selectedDates into a hash map
  const dMap = {};
  selectedDates
    .split(",")
    .map((d) => d.trim())
    .forEach((d) => {
      const dSplit = d.split(" ");
      if (dMap[dSplit[0]]) {
        dMap[dSplit[0]] = [...dMap[dSplit[0]], dSplit[1]];
      } else {
        dMap[dSplit[0]] = [dSplit[1]];
      }
    });

  // make selected dates into this format
  if (Object.keys(dMap).length > 0) {
    Object.keys(dMap).forEach((dm) => {
      sDateString = sDateString + ` ${dm} `; // insert month
      let count = 1;
      let dmLength = dMap[dm].length;
      dMap[dm].forEach((d) => {
        // insert date
        if (dmLength === count) {
          sDateString = sDateString + d;
        } else {
          sDateString = sDateString + ` ${d}, `;
        }

        count++;
      });
    });
  }

  // return this kind of format [Selected Date: Jul 24, 26]
  return `${t("paymentInfo.selectedDays")}: ${sDateString}`;
};

// display activity information
const DisplayActivityInfo = ({ details, transFee, total }) => {
  const { t } = useTranslation();

  return (
    <div className="display-activity-transaction-info">
      <h3> {t("paymentInfo.congratulations")}</h3>
      <p className="email">
        {details.child.name} ({details.child.email})
      </p>
      <div className="info">
        <div className="details">
          <h3>{details.activity.name}</h3>
          <label>{details.child.ageGroup}</label>
          <div className="time">
            <div>
              <img src={calendarIcon} alt="calendar" />
              {`${formatDateMonth(
                details.activity.fromDate
              )} - ${formatDateMonth(details.activity.toDate)}`}
            </div>
            <div>
              <img src={clockIcon} alt="clock" />
              {`${details.activity.startTime} - ${details.activity.endTime}`}
            </div>
          </div>
        </div>
        <div className="rates">
          {details.activity.dailySession && (
            <DisplaySelectedDates
              selectedDates={getActiveDates(
                details.child.days,
                details.activity.fromDate,
                details.activity.toDate
              )}
            />
          )}
          <div className="total-fee">
            {t("paymentInfo.transactionFee")}: <span>{`$${transFee}`}</span>
          </div>
          <div className="total-fee">
            {t("paymentInfo.activityFee")}:{" "}
            <span>{`$${details.paymentAmount}`}</span>
          </div>
        </div>
      </div>
      <div className="total">
        {t("paymentInfo.total")}: <span>{`$${total}`}</span>
      </div>
    </div>
  );
};

export default DisplayActivityInfo;
