/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useTranslation } from "react-i18next";

// common components
import Input from "../../components/input-fields/Input";

// scss
import "../../scss/views/FamilyProfile/GuardianForm.scss";

const FamilyForm = ({
  formValidation,
  setFormValidation,
  familyInfo,
  setFamilyInfo,
}) => {
  const { t } = useTranslation(); // translation

  const handleInputChange = (value, id) => {
    const tempValidation = { ...formValidation };

    // reset formValidation
    tempValidation[id].message = "";
    tempValidation[id].valid = true;
    setFormValidation({ ...tempValidation });

    // set new value
    const tempFamilyInfo = { ...familyInfo };
    tempFamilyInfo[id] = value;
    setFamilyInfo(tempFamilyInfo);
  };
  return (
    <div className="guardian-form">
      <div className="form">
        {Object.keys(familyInfo).map((key) => (
          <Input
            key={key}
            id={key}
            label={t(`familyProfile.${key}`)}
            type="text"
            value={familyInfo[key]}
            setter={handleInputChange}
            validation={formValidation[key]}
          />
        ))}
      </div>
    </div>
  );
};

export default FamilyForm;
