const RegisteredActivities = {
  registered: "Inscrit",
  waiting: "En attente",
  registerMore: "S'inscrire plus",
  center: "Centre",
  total: "Total",
  invoice: "Facture",
  downloadPDF: "Télécharger",
  currentYearHeading: "Activités inscrites depuis",
  previousYearHeading: "Activité enregistrée les années précédentes",
  PDFHeader: "FACTURE",
  PDFdate: "Date:",
  PDFinvoiceTo: "Facture à:",
  PDFactivityDetails: "Activité Détails",
  PDFsession: "Session",
  PDFdaily: "Quotidien",
  PDFfee: "Frais d'activité",
  PDFtrans: "Frais de Service",
  PDFtotal: "Réglé",
};
export default RegisteredActivities;
