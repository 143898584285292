import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// app context
import { useAppContext } from "../../context/AppContext";

// login sub-pages
import Email from "./Email";
import Password from "./Password";
import VerifyCode from "./VerifyCode";
import SetupPassword from "./SetupPassword";

// styling
import "../../scss/views/Login/login.scss";

// --- login entry ---
const Login = () => {
  const { t } = useTranslation();
  const { superId, centerId } = useAppContext();

  // logo url
  const logoUrl = superId
    ? `${process.env.REACT_APP_S3_PATH}/super/${superId}/logo.png`
    : `${process.env.REACT_APP_S3_PATH}/${centerId}/logo.png`;

  const [view, setView] = useState("email"); // email / password / access-code / setup-password
  const [email, setEmail] = useState("");

  // render page
  return (
    <div className="login">
      <img className="logo" alt="logo" src={logoUrl} />
      <div className="camp-registration">{t("login.registration")}</div>

      <div className="outline">
        {view === "email" ? (
          <Email email={email} setEmail={setEmail} changeView={setView} />
        ) : view === "password" ? (
          <Password email={email} changeView={setView} />
        ) : view === "access-code" ? (
          <VerifyCode email={email} changeView={setView} />
        ) : view === "setup-password" ? (
          <SetupPassword />
        ) : (
          <div>R&D Only -- wrong view type.</div>
        )}
      </div>
    </div>
  );
};

export default Login;
