const login = {
  registration: "Camps et activités d'inscription",
  signIn: "Se connecter ou s'inscrire",
  signInDes:
    "Veuillez entrer votre adresse e-mail pour vous connecter ou créer un nouveau compte",
  email: "Email",
  emailPlaceholder: "Entrez votre email",
  invalidEmail: "Email invalide",
  password: "Mot de passe",
  forgotPassword: "Mot de passe oublié",
  forgotPasswordTitle: "Vérifier le code de vérification",
  forgotPasswordMessage:
    "Veuillez vérifier votre e-mail et saisir le code de vérification.",
  resendCode: "Renvoyer le code",
  resendCodeTitle: "Code envoyé",
  resendCodeMessage:
    "Veuillez vérifier votre e-mail pour le code de vérification.",
  passwordDes: "Veuillez entrer le mot de passe",
  passwordPlaceholder: "Entrez votre mot de passe",
  wrongPassword: "Mot de passe incorrect",
  verificationCode: "Code de vérification",
  codeDes: "Veuillez entrer un code à 6 chiffres",
  codePlaceholder: "Entrez le code à 6 chiffres",
  invalidCode: "Code de vérification invalide",
  wrongCode: "Code de vérification incorrect",
  setupPassword: "Créer un mot de passe",
  newPassword: "Nouveau mot de passe",
  confirmPassword: "Confirmer le mot de passe",
  noPassword: "Veuillez entrer votre mot de passe",
  mismatchedPassword: "Mot de passe incorrect",
  backToEmail: "< Retour à l'email",
  page404: "Page non trouvée",
};

export default login;
