import React from "react";
import { useTranslation } from "react-i18next";

// components
import StatusButton from "../../components/displayReuseable/StatusButton";

// utils
import { formatDateMonth } from "../../utils/tools";
import { activityStatus } from "../../utils/activityStatus";

// images
import defaultImg from "../../assets/imgs/default-img.png";
import calendarIcon from "../../assets/icons/calendar-black.png";
import clockIcon from "../../assets/icons/clock-black.png";
import personIcon from "../../assets/icons/capacity-white.png";

// scss
import "../../scss/views/Registration/ActivityInfo.scss";

// activity information section
function ActivityInfo({ activity, goNext, isDisableNextButton }) {
  const { t } = useTranslation();

  // activity status
  const activityStatusType = activityStatus(activity); // status-join / status-waiting

  return (
    <div className="activity-info">
      <div className="left-container">
        <img
          style={{ objectFit: "cover" }}
          src={activity?.info?.imageUrl || defaultImg}
          alt={activity?.info?.name}
        />
        <div className="info">
          <h4>{activity?.info?.name}</h4>
          <div className="time">
            <div className="dateRange">
              <img src={calendarIcon} alt={activity?.info?.name} />
              {`${formatDateMonth(activity?.fromDate)} - ${formatDateMonth(
                activity.toDate
              )}`}
            </div>
            <div className="timeRange">
              <img src={clockIcon} alt={activity?.info?.name} />
              {`${activity?.info.startTime} - ${activity?.info.endTime}`}
            </div>
          </div>
        </div>
      </div>
      <div className="right-container">
        <StatusButton
          goNext={goNext}
          activity={activity}
          isDisableNextButton={isDisableNextButton}
        />
        <div className="capacity">
          <span className="capacity-title">
            {activityStatusType === "status-join"
              ? t("registration.activityCapacity")
              : t("registration.waitingCapacity")}
          </span>
          <span className={`capacity-number ${activityStatusType}`}>
            <img src={personIcon} alt={activity?.info.capacity} />
            {activityStatusType === "status-join"
              ? activity?.info.capacity
              : activity?.info.waiting}
          </span>
        </div>
      </div>
    </div>
  );
}

export default ActivityInfo;
