import { createContext, useContext, useState } from "react";

// auth context
const AuthContext = createContext();

// context provider
const AuthProvider = ({ children }) => {
  const uId = sessionStorage.getItem("_userId");
  const jToken = sessionStorage.getItem("_jwtToken");

  const [userId, setUserId] = useState(uId);
  const [jwtToken, setJwtToken] = useState(jToken);
  const [isAuthenticated, setAuthenticated] = useState(Boolean(uId && jToken));

  // user login to the app
  const login = (id, token) => {
    sessionStorage.setItem("_userId", id);
    sessionStorage.setItem("_jwtToken", token);
    setUserId(id);
    setJwtToken(token);
    setAuthenticated(true);
  };

  // user logout from the app
  const logout = () => {
    sessionStorage.clear();
    setUserId(null);
    setJwtToken(null);
    setAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{ userId, jwtToken, isAuthenticated, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// hook to get auth context
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
