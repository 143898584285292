import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// context
import { useAuth } from "../../context/AuthContext";
import { useAppContext } from "../../context/AppContext";

// app routes
import routes from "../../context/routes";

// backend service
import api from "../../services/api";
import request from "../../services/Request";

// components
import AddChildModal from "../../components/modals/AddChildModal";
import YellowButton from "../../components/buttons/YellowButton";

// icons
import EmailIcon from "../../assets/icons/emailIcon.png";
import EditIcon from "../../assets/icons/editIcon.png";
import EditIconDisabled from "../../assets/icons/editIconDisabled.png";

// style
import "../../scss/views/UserAccount/user-account.scss";

// user account page
const UserAccount = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const { userId, jwtToken } = useAuth();
  const { showLoading, showNotification, updateUserData } = useAppContext();

  const [familyData, setFamilyData] = useState({});
  const [payment, setPayment] = useState(null);
  const [familyProfileCompleted, setFamilyProfileCompleted] = useState(false);
  const [childData, setChildData] = useState([]);

  const [displayAddModal, setDisplayAddModal] = useState(false);

  // change route
  const changeRoute = (c, r) => {
    updateUserData({ selectedChild: c });
    history.push({ pathname: r, search: location.search });
  };

  // retrieve family info and associated children
  const loadData = async () => {
    try {
      showLoading(true);
      const res = await request.get(
        api.familyProfile,
        {
          userId: userId,
        },
        jwtToken
      );

      switch (res.status) {
        case 200:
          // save to local state
          setFamilyData(res.data.familyInfo);
          setPayment(res.data.familyPayment);
          setFamilyProfileCompleted(Boolean(res.data.familyInfo["1stName"]));
          setChildData(res.data.children);

          // update to global context
          updateUserData({
            familyInfo: res.data.familyInfo,
            children: res.data.children,
            familyPayment: res.data.familyPayment,
          });
          break;

        case 403:
          showNotification("expired");
          break;

        default:
          throw new Error("system-error");
      }
    } catch (e) {
      if (e.message === "system-error") {
        showNotification("system");
      } else {
        showNotification("network");
      }
    } finally {
      showLoading(false);
    }
  };

  // load data
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // add one child to the family
  const addChild = async (firstName, lastName) => {
    try {
      showLoading(true);
      const res = await request.put(
        api.familyProfile,
        {
          type: "add-child",
          userId: userId,
          info: {
            childName: `${firstName} ${lastName}`,
          },
        },
        jwtToken
      );

      switch (res.status) {
        case 200:
          setDisplayAddModal(false);
          showNotification(
            "success",
            t("userAccount.addedTitle"),
            t("userAccount.addedMessage"),
            loadData
          );
          break;

        case 403:
          showNotification("expired");
          break;

        default:
          throw new Error("system-error");
      }
    } catch (e) {
      if (e.message === "system-error") {
        showNotification("system");
      } else {
        showNotification("network");
      }
    } finally {
      showLoading(false);
    }
  };

  // user account page
  return displayAddModal ? (
    <AddChildModal
      setDisplayAddModal={setDisplayAddModal}
      addChild={addChild}
    />
  ) : (
    <div className="user-account-page" id="user-account-page">
      <div className="section-label">
        <h2>{t("userAccount.familyInformation")}</h2>
        {!familyProfileCompleted && (
          <p>{t("userAccount.familyInformationNote")}</p>
        )}
      </div>

      <div className="family-info">
        <div className="family-form-section">
          <div className="family-form-section-left">
            <img src={EmailIcon} alt="family-email-icon" />
            <p>{userId}</p>
          </div>
          <Link
            className="family-form"
            to={{ pathname: routes.FAMILY_PROFILE, search: location.search }}
          >
            <img src={EditIcon} alt="family-form-icon" />
            <p>
              {familyProfileCompleted
                ? t("userAccount.editFamilyForm")
                : t("userAccount.fillFamilyForm")}
            </p>
          </Link>
        </div>

        {familyProfileCompleted && (
          <div className="family-info-section">
            <div className="family-info-cards">
              <h4 className="family-info-card-header">
                {t("userAccount.guardian1")}
              </h4>
              <div className="family-info-card-body">
                <div className="family-info-card-fields">
                  <span>{t("userAccount.name")}</span>
                  <h5>{familyData["1stName"] || "--"}</h5>
                </div>
                <div className="family-info-card-fields">
                  <span>{t("userAccount.phone")}</span>
                  <h5>{familyData["1stPhone"] || "--"}</h5>
                </div>
                <div className="family-info-card-fields">
                  <span>{t("userAccount.cellPhone")}</span>
                  <h5>{familyData["1stCell"] || "--"}</h5>
                </div>
              </div>
            </div>

            <div className="family-info-cards">
              <h4 className="family-info-card-header">
                {t("userAccount.guardian2")}
              </h4>
              <div className="family-info-card-body">
                <div className="family-info-card-fields">
                  <span>{t("userAccount.name")}</span>
                  <h5>{familyData["2ndName"] || "--"}</h5>
                </div>
                <div className="family-info-card-fields">
                  <span>{t("userAccount.email")}</span>
                  <h5>{familyData["2ndEmail"] || "--"}</h5>
                </div>
                <div className="family-info-card-fields">
                  <span>{t("userAccount.cellPhone")}</span>
                  <h5>{familyData["2ndCell"] || "--"}</h5>
                </div>
              </div>
            </div>

            <div className="family-info-cards">
              <h4 className="family-info-card-header">
                {t("userAccount.emergencyContact")}
              </h4>
              <div className="family-info-card-body">
                <div className="family-info-card-fields">
                  <span>{t("userAccount.name")}</span>
                  <h5>{familyData.emName || "--"}</h5>
                </div>
                <div className="family-info-card-fields">
                  <span>{t("userAccount.phone")}</span>
                  <h5>{familyData.emCell || "--"}</h5>
                </div>
              </div>
            </div>

            <div className="family-info-cards">
              <h4 className="family-info-card-header">
                {t("userAccount.paymentMethod")}
              </h4>
              <div className="family-info-card-body">
                <div className="family-info-card-fields">
                  <span>{t("userAccount.name")}</span>
                  <h5>{payment?.name || "--"}</h5>
                </div>
                <div className="family-info-card-fields">
                  <span>{t("userAccount.creditCard")}</span>
                  <h5>{payment?.card || "--"}</h5>
                </div>
                <div className="family-info-card-fields">
                  <span>{t("userAccount.expiry")}</span>
                  <h5>{payment?.expiry || "--"}</h5>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="section-label">
        <h2>{t("userAccount.childInformation")}</h2>
      </div>
      <div className="child-info">
        {childData.length === 0 ? (
          <div
            className={
              familyProfileCompleted
                ? "child-form-enabled"
                : "child-form-disabled"
            }
            onClick={() => {
              if (familyProfileCompleted) {
                setDisplayAddModal(true);
              }
            }}
          >
            <img
              src={familyProfileCompleted ? EditIcon : EditIconDisabled}
              alt="child-form-icon"
            />
            <p>{t("userAccount.addChild")}</p>
          </div>
        ) : (
          <div className="child-info-list-section">
            <div className="child-info-list-section-header">
              <h4>{t("userAccount.childName")}</h4>
              <button
                className="child-form-active"
                onClick={() => {
                  setDisplayAddModal(true);
                }}
              >
                <img src={EditIcon} alt="child-form-icon" />
                <p>{t("userAccount.addChild")}</p>
              </button>
            </div>
            <div className="child-info-list-section-body">
              {childData.map((child, index) => (
                <div key={index} className="child-info-list">
                  <div className="child-info-list-left-section">
                    <h5>{child.name}</h5>
                    <div
                      className="form-fillUp"
                      onClick={() => {
                        changeRoute(child, routes.CHILD_PROFILE);
                      }}
                    >
                      <img src={EditIcon} alt="child-form-icon" />
                      <p>
                        {child.formIncomplete
                          ? t("userAccount.completeChildForm")
                          : t("userAccount.editChildForm")}
                      </p>
                    </div>
                  </div>
                  <div className="child-info-list-right-section">
                    {child.formIncomplete ? (
                      <p>{t("userAccount.registrationInstruction")}</p>
                    ) : (
                      <YellowButton
                        className="registration-history"
                        onClick={() => {
                          changeRoute(child, routes.REGISTERED_ACTIVITIES);
                        }}
                      >
                        <p>{t("userAccount.registrationHistory")}</p>
                      </YellowButton>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserAccount;
