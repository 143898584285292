const questions = [
  {
    id: "consent_0",
    tipKey: "",
  },
  {
    id: "consent_1",
    tipKey: "",
  },
  {
    id: "consent_2",
    tipKey: "",
  },
  {
    id: "consent_3",
    tipKey: "",
  },
  {
    id: "consent_4",
    tipKey: "consent_4Add",
  },
  {
    id: "consent_5",
    tipKey: "consent_5Add",
  },
];

export default questions;
