// regular expression for validation
const validPhone = /^\d{10}$/; // 10-digit phone number
const validVerificationCode = /^\d{6}$/; // 6-digit verification code
const validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // email format
const validNumber = /^\d+$/; // integer number only

const formatPhoneNumber = (n) => {
  if (!n) return "";

  let formatted = "";
  if (n.length > 3) {
    formatted += n.slice(0, 3);
    if (n.length >= 6) {
      formatted += "-" + n.slice(3, 6);
      if (n.length > 6) {
        formatted += "-" + n.slice(6);
      }
    } else {
      formatted += "-" + n.slice(3);
    }
  } else {
    formatted = n;
  }

  return formatted;
};

export {
  validEmail,
  validPhone,
  validVerificationCode,
  validNumber,
  formatPhoneNumber,
};
