// genderOptions.js
import { useTranslation } from "react-i18next";

const GenderOptions = () => {
  const { t } = useTranslation();

  const genderOptions = [
    {
      value: "Male",
      label: t("childProfile.male"),
    },
    {
      value: "Female",
      label: t("childProfile.female"),
    },
    {
      value: "Unknown",
      label: t("childProfile.unknown"),
    },
    {
      value: "Other",
      label: t("childProfile.other"),
    },
  ];

  return genderOptions;
};

export default GenderOptions;
