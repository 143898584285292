import common from "./Common";
import login from "./views/Login";
import userAccount from "./views/UserAccount";
import familyProfile from "./views/FamilyProfile";
import childProfile from "./views/ChildProfile";
import centerList from "./views/CenterList";
import activityList from "./views/ActivityList";
import registration from "./views/Registration";
import registeredActivities from "./views/RegisteredActivities";
import paymentInfo from "./views/PaymentInfo";

const translation = {
  common,
  login,
  userAccount,
  familyProfile,
  childProfile,
  centerList,
  activityList,
  registration,
  registeredActivities,
  paymentInfo,
};

export default translation;
