import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import i18n from "../../locales/i18n";

//util for display date from bitmap
import { mappingDays } from "../mappingDays";

// Register fonts
pdfMake.vfs = pdfFonts.pdfMake.vfs;

// function to generate PDF
const generatePDF = (activityData, userData, userId) => {
  // formatted dates
  const selectedDates = mappingDays(
    activityData.fromDate,
    activityData.toDate,
    activityData.days
  );

  // styles for PDF
  const pdfStyles = {
    info: {
      title: "Registered Activity Details",
      author: "Esikidz",
      subject: "Child Activity Registration Details",
      keywords: "Esikidz, Activity, Registration, Child, Details",
    },

    pageSize: "LETTER", // 612x792
    pageMargin: [40, 33, 40, 33],
    content: [
      {
        // header background
        canvas: [
          {
            type: "rect",
            x: 0,
            y: 0,
            w: 620,
            h: 150,
            color: "#003A4A",
          },
        ],
        absolutePosition: { x: 0, y: 0 },
      },
      {
        // header text
        text: i18n.t("registeredActivities.PDFHeader"),
        style: {
          fontSize: 40,
          color: "white",
          bold: true,
        },
        margin: [0, 21, 0, 21],
        alignment: "right",
      },
      {
        // subheader background
        canvas: [
          {
            type: "rect",
            x: 0,
            y: 0,
            w: 620,
            h: 50,
            color: "#F5F5F5",
          },
        ],
        absolutePosition: { x: 0, y: 150 },
      },
      // subheader text
      {
        text: activityData.center,
        fontSize: 16,
        bold: true,
        alignment: "left",
        relativePosition: { x: 0, y: 35 },
      },
      {
        columns: [
          {
            width: "*",
            text: i18n.t("registeredActivities.PDFdate"),
            alignment: "right",
            bold: true,
          },
          {
            width: 100,
            text: activityData.regDate,
            alignment: "right",
          },
        ],
        fontSize: 16,
        relativePosition: { x: 0, y: 35 },
      },
      // customer info
      {
        table: {
          body: [
            [
              {
                text: i18n.t("registeredActivities.PDFinvoiceTo"),
                fontSize: 18,
                bold: true,
                margin: [0, 90, 0, 0],
              },
              {
                type: "none",
                ol: [
                  {
                    text:
                      `${userData.familyInfo["1stName"]} (${userData.selectedChild.name})`,
                    fontSize: 16,
                    bold: true,
                    margin: [0, 2, 0, 10],
                  },
                  { text: userId, margin: [0, 0, 0, 10] },
                  {
                    text: userData.familyInfo["1stPhone"],
                    margin: [0, 0, 0, 0],
                  },
                ],
                margin: [-10, 90, 0, 0],
              },
            ],
          ],
        },
        layout: "noBorders",
      },
      // price table
      {
        table: {
          widths: ["*", "*", "*", "*"],
          body: [
            [
              {
                text:
                  "\n" +
                  i18n.t("registeredActivities.PDFactivityDetails") +
                  "\n\n",
                color: "white",
                fillColor: "#003A4A",
                alignment: "center",
              },
              { text: "", fillColor: "#003A4A" },
              {
                text:
                  "\n" +
                  i18n.t("registeredActivities.PDFsession") +
                  " / " +
                  i18n.t("registeredActivities.PDFdaily"),
                fillColor: "#dddddd",
                alignment: "center",
                bold: true,
              },
              {
                text: "\n" + i18n.t("registeredActivities.PDFfee"),
                fillColor: "#dddddd",
                alignment: "center",
                bold: true,
              },
            ],
          ],
        },
        margin: [0, 20, 0, 2],
        layout: "noBorders",
      },
      {
        table: {
          widths: ["*", "*", "*", "*"],
          body: [
            [
              {
                stack: [
                  {
                    type: "none",
                    ul: [
                      {
                        text: "\n" + activityData.activity,
                        bold: true,
                        margin: [0, 0, 0, 5],
                      },
                      { text: activityData.ageGroup, margin: [0, 0, 0, 5] },
                      {
                        text:
                          activityData.days !== ""
                            ? selectedDates
                            : `${activityData.fromDate} ~ ${activityData.toDate}`,
                        margin: [0, 0, 0, 10],
                      },
                    ],
                  },
                ],
                colSpan: 2,
              },
              [""],
              [
                {
                  text:
                    "\n\n" +
                    (activityData.days !== ""
                      ? `${i18n.t("registeredActivities.PDFdaily")}`
                      : `${i18n.t("registeredActivities.PDFsession")}`),
                  alignment: "center",
                  bold: true,
                },
              ],
              [
                {
                  text:
                    "\n\n$" + (activityData.actFee || activityData.totalFee),
                  alignment: "center",
                  bold: true,
                },
              ],
            ],
          ],
        },
        margin: [0, 0, 0, 20],
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 1 : 0;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 1 : 0;
          },
          hLineColor: function (i, node) {
            return i === 0 || i === node.table.body.length ? "grey" : "none";
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length ? "grey" : "none";
          },
        },
      },
      // service fee
      {
        table: {
          widths: ["*", "*", "*", "*"],
          body: [
            [
              "",
              "",
              {
                text: i18n.t("registeredActivities.PDFtrans"),
                alignment: "left",
                bold: true,
                margin: [30, 0, 0, 0],
              },
              {
                text: "$" + (activityData.transFee || 0),
                alignment: "center",
                bold: true,
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 0, 0, 20],
      },
      // total price
      {
        table: {
          widths: ["*", "*", "*", "*"],
          body: [
            [
              "",
              "",
              {
                text: "\n" + i18n.t("registeredActivities.PDFtotal"),
                fontSize: 18,
                color: "white",
                alignment: "center",
                bold: true,
                fillColor: "#003A4A",
              },
              {
                text: "\n$" + (activityData.totalFee || 0) + "\n\n",
                fontSize: 18,
                color: "white",
                alignment: "center",
                bold: true,
                fillColor: "#003A4A",
              },
            ],
          ],
        },
        layout: "noBorders",
      },
    ],
  };

  // PDF document definition
  const documentDefinition = {
    ...pdfStyles,
    content: pdfStyles.content,
  };

  // Generate PDF by the activity & child name
  pdfMake
    .createPdf(documentDefinition)
    .download(`${userData.selectedChild.name}-${activityData.activity}.pdf`);
};

export default generatePDF;
