import React from "react";
import { useTranslation } from "react-i18next";

// stripe element
import { Elements } from "@stripe/react-stripe-js";

// common component
import Input from "../input-fields/Input";
import CreditCard from "./CreditCard";

// style
import "../../scss/components/payment/PaymentForm.scss";

// payment form
const PaymentForm = ({
  name,
  setName,
  stripeObject,
  creditCardRef,
  validation,
}) => {
  const { t } = useTranslation(); // translation

  const handleInputChange = (value, id) => {
    setName(value);
  };

  // render payment form
  return (
    <div className="payment-form">
      <Input
        id="cardholderName"
        label={`${t("familyProfile.cardholderName")}*`}
        type="text"
        value={name}
        setter={handleInputChange}
        validation={validation}
      />
      <div className="credit-card-input">
        <div className="header">
          <span>{t("familyProfile.cardNumber")}*</span>
          <div className="expiry-cvv">
            <span>{t("familyProfile.expiry")}*</span>
            <span>{t("familyProfile.CVV")}*</span>
          </div>
        </div>
        <div className="container">
          <Elements stripe={stripeObject}>
            <CreditCard ref={creditCardRef} name={name} />
          </Elements>
        </div>
      </div>
    </div>
  );
};

export default PaymentForm;
