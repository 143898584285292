export const activityStatus = (activity) => {
  const registrationCapacity = activity?.info?.capacity; // max child that can be accomodated
  const waitingCapacity = activity?.info?.waiting;
  const waitings = activity?.waitings;
  const isDailySession = activity?.info?.dailySession;

  if (isDailySession) {
    //check the number of the registered child
    // item.registers will be an array
    const registrationSlots = [...activity.registers];

    let fullSlotCount = 0; // count the number of full slots
    registrationSlots.forEach((registeredSlotCount) => {
      if (registeredSlotCount >= activity?.info?.capacity) {
        fullSlotCount++;
      }
    });

    if (fullSlotCount < registrationSlots.length) {
      return "status-join";
    }
  } else {
    // item.registers will be a number
    const registeredCount = activity?.registers;

    if (registrationCapacity > registeredCount) {
      return "status-join";
    }
  }

  if (waitings < waitingCapacity) {
    return "status-waiting";
  } else {
    return "status-full";
  }
};
