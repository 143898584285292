import React from "react";
import ReactDOM from "react-dom/client";

// i18n initialization
import i18n from "./locales/i18n"; // eslint-disable-line no-unused-vars

// app entry
import App from "./App";

// app level styling
import "./../src/scss/index.scss";

// app entry
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
