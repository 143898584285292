import React from "react";

import "../../scss/components/button/YellowButton.scss";

/**
 * @return{React.Component}
 */
function YellowButton({ onClick, children, className, disabled = false }) {
  return (
    <button
      className={`yellow-button ${className}`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export default YellowButton;
