import React from "react";
import "../../scss/components/loading.scss";

/**
 * @return {JSX.Element}
 */
function Loading() {
  return (
    <div>
      <div className="loader">
        <div className="loader-center" />
      </div>
      <div className="mask" />
    </div>
  );
}

export default Loading;
