import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

// context and routes
import { useAppContext } from "../../context/AppContext";

// backend service
import api from "../../services/api";
import request from "../../services/Request";

// stripe utils
import { loadStripe } from "@stripe/stripe-js";

// components
import DisplayActivityInfo from "./DisplayActivityInfo";
import PaymentSuccessfullyPage from "./PaymentSuccessfullyPage";
import PaymentForm from "../../components/payment/PaymentForm";
import BlueButton from "../../components/buttons/BlueButton";

// image
import canadaFlag from "../../assets/icons/canada.png";

// utils
import { serviceCharge, totalCharge } from "../../utils/tools";

//styles
import "../../scss/views/PaymentInfo/PaymentInfo.scss";

// payment information page
const PaymentInfo = () => {
  const creditCardRef = useRef(); // credit card ref

  const { i18n, t } = useTranslation();
  const { showLoading, showNotification, paymentInfo } = useAppContext();

  const [view, setView] = useState(null); // invalid-link / payment-info / succeed-page
  const [info, setInfo] = useState(null); // information for this payment

  const [cardholderName, setCardholderName] = useState("");
  const [validName, setValidName] = useState(true);

  const [isMounted, setIsMounted] = useState(false); // tracks if the component is mounted or not
  const [stripeObject, setStripeObject] = useState(null);

  // load payment data
  useEffect(() => {
    const loadData = async () => {
      try {
        showLoading(true);
        const res = await request.get(api.waitingPayment, {
          centerId: paymentInfo.centerId,
          childId: paymentInfo.childId,
          activityId: paymentInfo.activityId,
        });

        switch (res.status) {
          case 200:
            setInfo(res.data);
            setView("payment-info");
            setIsMounted(true); // component is mounted
            break;

          case 208:
            setView("invalid-link"); // display invalid link message
            break;

          default:
            throw new Error("system-error");
        }
      } catch (e) {
        if (e.message === "system-error") {
          showNotification("system");
        } else {
          showNotification("network");
        }
      } finally {
        showLoading(false);
      }
    };

    // load payment data
    loadData();
    return () => {
      setIsMounted(false); // component is not mounted
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ----load stripe object only ONCE! -----
  useEffect(() => {
    const fetchStripeObject = async () => {
      const accountId = info.sAccount;

      // don't load stripe without accountId
      if (accountId) {
        const res = await loadStripe(
          process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
          {
            stripeAccount: accountId, // center connected account
          }
        );

        setStripeObject(res);
      }
    };

    // perform fetchStripeObject only when component is mounted to prevent memory leak warning
    if (isMounted) {
      fetchStripeObject(); // cal fetchStripeObject
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);
  // ----load stripe object only ONCE! -----

  // handle waiting payment
  const handleWaitingPayment = async () => {
    // validate card holder name
    if (!cardholderName) {
      setValidName(false);
      return;
    }

    try {
      showLoading(true);

      // get credit card payment method
      const method = await creditCardRef.current.handlePay();
      if (!method) return;

      // register waiting child with payment info
      const res = await request.put(api.waitingPayment, {
        centerId: paymentInfo.centerId,
        childId: paymentInfo.childId,
        activityId: paymentInfo.activityId,
        days: info.child.days,
        paymentInfo: {
          id: method.id,
          name: cardholderName,
          fee: info.paymentAmount,
          trans: serviceCharge(info.paymentAmount, info.calcTrans, info.sRate),
          amount: totalCharge(info.paymentAmount, info.calcTrans, info.sRate),
        },
      });

      switch (res.status) {
        case 200:
          setView("succeed-page");
          break;

        default:
          throw new Error("system-error");
      }
    } catch (e) {
      if (e.message === "system-error") {
        showNotification("system");
      } else {
        showNotification("network");
      }
    } finally {
      showLoading(false);
    }
  };

  // payment info page
  return (
    <div className="payment-info">
      <div className="app-header">{info?.centerName}</div>
      <div className="content">
        <div className="language-bar">
          <img src={canadaFlag} alt="canada" />
          <div
            className="lan"
            onClick={() => {
              i18n.changeLanguage(i18n.language === "en" ? "fr" : "en");
            }}
          >
            {i18n.language === "en" ? "Français" : "English"}
          </div>
        </div>

        {!view ? (
          <></>
        ) : view === "invalid-link" ? (
          <div className="invalid-link">
            <h3>{t("paymentInfo.invalidLink")}</h3>
            <p>{t("paymentInfo.invalidLinkHint")}</p>
          </div>
        ) : view === "payment-info" ? (
          <>
            <DisplayActivityInfo
              details={info}
              transFee={serviceCharge(
                info.paymentAmount,
                info.calcTrans,
                info.sRate
              )}
              total={totalCharge(
                info.paymentAmount,
                info.calcTrans,
                info.sRate
              )}
            />
            <div className="pay-section">
              <PaymentForm
                name={cardholderName}
                setName={(n) => {
                  setValidName(true);
                  setCardholderName(n);
                }}
                stripeObject={stripeObject}
                creditCardRef={creditCardRef}
                validation={{
                  valid: validName,
                  message: t("familyProfile.nameIsRequired"),
                }}
              />
              <BlueButton className="pay-button" onClick={handleWaitingPayment}>
                {t("paymentInfo.pay")}
              </BlueButton>
            </div>
          </>
        ) : (
          <PaymentSuccessfullyPage
            details={info}
            total={totalCharge(info.paymentAmount, info.calcTrans, info.sRate)}
          />
        )}
      </div>
    </div>
  );
};

export default PaymentInfo;
