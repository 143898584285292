// function to convert activity days (bitmap) to individual days
const mappingDays = (startDate, endDate, days) => {
  const validDays = [];
  // loop through the date range
  let index = 0;
  for (
    let day = new Date(
      parseInt(startDate.split("-")[0]),
      parseInt(startDate.split("-")[1]) - 1,
      parseInt(startDate.split("-")[2])
    );
    day <=
    new Date(
      parseInt(endDate.split("-")[0]),
      parseInt(endDate.split("-")[1]) - 1,
      parseInt(endDate.split("-")[2])
    );
    day.setDate(day.getDate() + 1)
  ) {
    if (days.charAt(index) === "1") {
      // convert to string
      validDays.push(
        day.toLocaleDateString("en-us", {
          // year: "numeric",
          month: "short",
          day: "numeric",
        })
      );
    }
    index++;
  }

  return validDays.join(", ");
};

export { mappingDays };
