/**
 * The function checks if a value is not null, not undefined, and not an empty string.
 * @param value - The value to be tested for whether it is required or not.
 * @returns The function `testRequired` returns a boolean value indicating whether the input `value` is
 * considered "required" or not. It returns `true` if the `value` is not null, not undefined, and not
 * an empty string (after trimming any leading/trailing whitespace). Otherwise, it returns `false`.
 */
const testRequired = (value) => {
  if (value) {
    if (value === null) {
      return false;
    }
    //convert to string if not string
    const valueString =
      typeof value === "string" ? value.trim() : value.toString().trim();

    //just test string length
    return valueString.length !== 0;
  }
  return false;
};

/**
 * The function tests if an email is valid based on a regular expression and whether it is required or
 * not.
 * @param email - The email address to be tested for validity.
 * @param isRequired - A boolean value indicating whether the email is required or not. If it is set to
 * true, the function will return false if the email parameter is empty or null.
 * @returns The function `testEmail` returns a boolean value. It returns `true` if the email is valid
 * according to the regular expression pattern, or if the email is not required and is blank. It
 * returns `false` if the email is null or if it is required but is blank or does not match the regular
 * expression pattern.
 */
const testEmail = (email, isRequired) => {
  //not required and email is blank test pass
  if (!isRequired && email.trim().length === 0) {
    return true;
  }

  if (email) {
    if (email === null) {
      return false;
    }
    /* eslint-disable no-useless-escape */
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    // test valid email Pattern
    return emailRegex.test(email);
  }
  return false;
};

/**
 * The function tests if a given mobile number is valid according to a specific pattern.
 * @param mobileNumber - The phone number that needs to be tested for validity.
 * @param isRequired - A boolean value indicating whether the mobile number is required or not. If it
 * is not required and the mobile number is blank, the test will pass.
 * @returns a boolean value indicating whether the provided mobile number is valid or not. If the
 * mobile number is valid, the function returns true, otherwise it returns false.
 */
const testMobileNumber = (mobileNumber, isRequired) => {
  //not required and mobileNumber is blank test pass
  if (!isRequired && mobileNumber.trim().length === 0) {
    return true;
  }

  if (mobileNumber) {
    if (mobileNumber === null) {
      return false;
    }
    /* eslint-disable no-useless-escape */
    // const phoneRegex = /^\(?([0-9]{3})\)?[- ]?([0-9]{3})[- ]?([0-9]{4})$/;
    const phoneRegex = /^[0-9+()#.\s/ext-]+$/;

    // test valid mobile number pattern
    return phoneRegex.test(mobileNumber);
  }

  return false;
};

const testAttendanceSchedule = (schedule) => {
  if (schedule === "00000#00000") {
    return false;
  }
  return true;
};

/**
 * The function validates input fields based on specified rules and returns an object with the
 * validation results.
 * @param validationFields - An object containing fields to be validated. Each field is represented by
 * a key-value pair, where the key is the name of the field and the value is an object containing the
 * field's value and validation rules.
 * @returns The function `validate` returns an object that contains the input fields with their
 * validation status and error messages.
 */
const validate = (validationFields) => {
  const inputFields = JSON.parse(JSON.stringify(validationFields)); // deep clone object

  let totalInValid = 0;
  // loop thru the fields
  Object.keys(inputFields).forEach((field) => {
    // validate if field has rules
    if (inputFields[field] !== null && inputFields[field].rules) {
      const rules = inputFields[field].rules; // get rules
      const fieldValue = inputFields[field].value; // get value
      let inValidCounts = 0; // count invalid

      // check if field is required
      const isRequired = rules.filter((r) => r === "required").length > 0;

      rules.every((r) => {
        // required
        if (r === "required") {
          const flag = testRequired(fieldValue);

          // not pass condition then it is invalid stop loop
          if (!flag) {
            inputFields[field].valid = false;
            inputFields[field].message = "common.requiredField";
            inValidCounts++;
            return false;
          }
        }

        // emailFormat
        if (r === "emailFormat") {
          const flag = testEmail(fieldValue, isRequired);

          // not pass condition then it is invalid stop loop
          if (!flag) {
            inputFields[field].valid = false;
            inputFields[field].message = "common.invalidEmail";
            inValidCounts++;
            return false;
          }
        }

        // phoneNumberFormat
        if (r === "phoneNumberFormat") {
          const flag = testMobileNumber(fieldValue, isRequired);

          // not pass condition then it is invalid stop loop
          if (!flag) {
            inputFields[field].valid = false;
            inputFields[field].message = "common.invalidPhone";
            inValidCounts++;
            return false;
          }
        }

        // attendanceSchedule
        if (r === "attendanceSchedule") {
          const flag = testAttendanceSchedule(fieldValue, isRequired);

          // not pass condition then it is invalid stop loop
          if (!flag) {
            inputFields[field].valid = false;
            inputFields[field].message = "";
            inValidCounts++;
            return false;
          }
        }

        // checkRequired
        if (r === "checkRequired") {
          // not pass condition then it is invalid stop loop
          if (!fieldValue) {
            inputFields[field].valid = false;
            inValidCounts++;
            return false;
          }
        }

        return true;
      });

      // clear valid and message for the field if no invalid
      totalInValid = totalInValid + inValidCounts;
      if (inValidCounts === 0) {
        inputFields[field].valid = true;
        inputFields[field].message = "";
      }
    }
  });

  // if one of the fields has invalid then valid will be false
  inputFields.valid = totalInValid === 0;

  return inputFields;
};

export { validate };
