const Registration = {
  preRegistration0: "* L'activité n'est pas ouverte au public",
  preRegistration1: "* Ouverture de l'activité au public à 9h00 le",
  joinTheSession: "Rejoindre",
  addToWaitingList: "En attente",
  sessionFull: "Session complet",
  capacity: "Capacité",
  activityCapacity: "Capacité d'activité",
  waitingCapacity: "Capacité d'attente",
  day: "Jour",
  dailyRate: "Tarif journalier",
  sessionRate: "Tarif de session",
  total: "Total",
  selectAgeGroup: "Sélectionner groupe d'âge",
  pleaseSelectAgeGroup: "Veuillez sélectionner groupe d'âge",
  selectTheDate: "Sélectionner la date",
  remainingActivitySpace: "Espace d'activité restant",
  remainingWaitingSpace: "Espace d'attente restant",
  fees: "Frais",
  activityDisclaimer: "Avertissement d'activité",
  disclaimerTitle: "Avertissement",
  noDisclaimer: "Aucun avertissement",
  confirmationTitle: "Confirmer votre inscription",
  registerFor: "Inscription à ",
  formIncompleteTitle: "Formulaire incomplet",
  formIncompleteMessage:
    "Veuillez compléter formulaire de l'enfant dans 'Compte utilisateur'.",
  paymentMethod: "Méthode de paiement",
  name: "Nom du titulaire de la carte",
  cardNumber: "Numéro de carte",
  expiry: "Expiration",
  saveCard:
    "Ajoutez cette carte à votre profil familial pour des transactions futures pratiques.",
  confirmPay: "Confirmer et s'inscrire",
  registeredTitle: "Félicitations",
  registeredMessage:
    "Vous avez inscrit votre enfant à l'activité avec succès. Un email de confirmation vous a été envoyé.",
  waitingTitle: "Félicitations",
  waitingMessage:
    "Vous avez mis votre enfant sur la liste d'attente de l'activité avec succès. Un email de confirmation vous a été envoyé.",
  cancel: "Annuler",
  sessionFee: "Session Frais",
  transactionFee: "Frais de Service de Plateforme",
  noChild: "Veuillez ajouter un enfant pour inscrire à l'activité",
  restrictedTitle: "Fermé",
  restrictedMessage0: "L'activité n'est pas ouverte au public",
  restrictedMessage1: "L'activité sera ouverte au public à 9h00 le",
  inListTitle: "Inscrit déjà",
  inListMessage: "L'enfant est déjà inscrit sur la liste d'attente.",
  noSpaceTitle: "Il ne reste plus de place",
  noSpaceMessage:
    "Il n'y a plus de place disponible pour l'activité maintenant.",
  paymentFailedTitle: "Paiement échoué",
  paymentFailedMessage: "Le paiement a échoué en raison de",
};
export default Registration;
