const familyProfile = {
  heading: "Information Familiale",
  "1stName": "Nom tuteur 1 (Prénom et Nom de Famille)*",
  "1stPhone": "Tél. fixe*",
  "1stCell": "Tél. portable tuteur 1*",
  "2ndName": "Nom tuteur 2 (Prénom et Nom de Famille)*",
  "2ndCell": "Tél. portable tuteur 2*",
  "2ndEmail": "Email tuteur 2 (facultatif)",
  emName: "Nom contact urgence (Prénom et Nom de Famille)*",
  emCell: "Tél. portable urgence*",
  back: "Retour",
  cardholderName: "Nom titulaire carte",
  cardNumber: "Numéro carte",
  expiry: "Expiration",
  CVV: "CVV",
  paymentMethod: "Moyen paiement (facultatif)",
  submit: "Soumettre",
  cancel: "Annuler",
  savedTitle: "Enregistré",
  savedMessage: "Infos famille enregistrées.",
  noPaymentMethod:
    "Ajouter carte crédit ici peut vous faire gagner du temps pour paiements futurs.",
  addCard: "Ajouter carte",
  removeCard: "Supprimer carte",
  nameIsRequired: "Nom requis",
};

export default familyProfile;
