import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// context and routes
import { useAuth } from "../../context/AuthContext";
import { useAppContext } from "../../context/AppContext";
import routes from "../../context/routes";

// backend service
import api from "../../services/api";
import request from "../../services/Request";

// utils
import { formatDateMonth } from "../../utils/tools";
import { mappingDays } from "../../utils/mappingDays";

// common components
import BackLine from "../../components/backLine/BackLine";
import BlueButton from "../../components/buttons/BlueButton";
import SingleSelect from "../../components/input-fields/SingleSelect";

// images
import noDataIcon from "../../assets/icons/no-data-icon.png";

// pdf generator
import generatePDF from "../../utils/pdfGenerator/pdfBuilder";

// scss
import "../../scss/views/RegisteredActivities/RegisteredActivities.scss";

// activity section
const Activity = ({ act, userData, userId }) => {
  const { t } = useTranslation();

  return (
    <div className="registered-activity">
      <div className="registeredDetails">
        <div className="registeredDetails__info">
          <div className="registeredActivityName">{act.activity}</div>
        </div>
        {act.days.length > 0 ? (
          <div className="registeredDetails__schedule">
            <div className="registeredDetails__schedule__selectedDates">
              {mappingDays(act.fromDate, act.toDate, act.days)}
            </div>
            <div className="registeredDetails__schedule__time">
              {act.startTime} - {act.endTime}
            </div>
          </div>
        ) : (
          <div className="registeredDetails__schedule">
            <div className="registeredDetails__schedule__date">
              {`${formatDateMonth(act.fromDate)} - ${formatDateMonth(
                act.toDate
              )}`}
            </div>
            <div className="registeredDetails__schedule__time">
              {act.startTime} - {act.endTime}
            </div>
          </div>
        )}
        <div className="registeredDetails__center">{act.center}</div>

        <div className="registeredDetails__totalFee">
          <h2>${act.totalFee}</h2>
        </div>
        <BlueButton
          className="registeredDetails__downloadPDF"
          onClick={() => {
            generatePDF(act, userData, userId);
          }}
        >
          {t("registeredActivities.downloadPDF")}
        </BlueButton>
      </div>
    </div>
  );
};

// registered activities page
const RegisteredActivities = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const { jwtToken, userId } = useAuth();
  const { entryPoint, showLoading, showNotification, userData } =
    useAppContext();

  const currentYear = new Date().getFullYear();
  const [currentYearActivities, setCurrentYearActivities] = useState([]);

  const [selectedYear, setSelectedYear] = useState("");
  const [selectedYearActivities, setSelectedYearActivities] = useState([]);

  const rightBtn = () => {
    return (
      <BlueButton
        className="registerMore-btn"
        onClick={() =>
          history.push({
            pathname:
              entryPoint === "super"
                ? routes.CENTER_LIST
                : routes.ACTIVITY_LIST,
            search: location.search,
          })
        }
      >
        {t("activityList.registerMore")}
      </BlueButton>
    );
  };

  // year selection range within 5 years
  const yearOptions = [];
  for (let i = 1; i < 6; i++) {
    const year = currentYear - i;
    yearOptions.push({
      value: year.toString(),
      label: year.toString(),
    });
  }

  // load activity list for specific year
  const loadData = async (isCurrentYear, year) => {
    try {
      showLoading(true);
      const res = await request.get(
        api.registeredActivities,
        {
          childId: userData.selectedChild.childId,
          isCurrentYear: isCurrentYear,
          year: year,
        },
        jwtToken
      );

      switch (res.status) {
        case 200:
          if (isCurrentYear) {
            setCurrentYearActivities(res.data);
          } else {
            setSelectedYearActivities(res.data);
          }
          break;

        case 403:
          showNotification("expired");
          break;

        default:
          throw new Error("system-error");
      }
    } catch (e) {
      if (e.message === "system-error") {
        showNotification("system");
      } else {
        showNotification("network");
      }
    } finally {
      showLoading(false);
    }
  };

  // load activity list beyond current year
  useEffect(() => {
    loadData(
      true, // current year
      currentYear
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handle year selection
  const handleYearSelection = (year) => {
    setSelectedYear(year);
    loadData(
      false, // past year
      year
    );
  };

  return (
    <div className="center-activity-list">
      <BackLine route={routes.USER_ACCOUNT} rightBtn={rightBtn()} />
      <div className="registeredActivityHeading">
        <h2>{userData.selectedChild.name}</h2>
      </div>
      <div className="activity-year-separator">
        {t("registeredActivities.currentYearHeading")} {currentYear}
      </div>
      <div className="registeredLabel">
        <h2 className="registeredLabel-heading">
          {t("activityList.campInfo")}
        </h2>
        <div className="registeredLabel-info">
          <h2>{t("activityList.campInfo")}</h2>
          <h2>{t("activityList.campTime")}</h2>
          <h2>{t("registeredActivities.center")}</h2>
          <h2>{t("registeredActivities.total")}</h2>
          <h2>{t("registeredActivities.invoice")}</h2>
        </div>
      </div>
      <div className="registeredActivities">
        {currentYearActivities.length > 0 ? (
          <>
            {currentYearActivities.map((item, index) => (
              <Activity
                key={item.fromDate + index}
                act={item}
                userData={userData}
                userId={userId}
              />
            ))}
          </>
        ) : (
          <div className="no-data-container">
            <img src={noDataIcon} alt="no data found" />
            {t("activityList.noResult")}
          </div>
        )}
        <br />
        <div className="activity-year-separator">
          {t("registeredActivities.previousYearHeading")}
        </div>
        <div className="year-selector">
          <SingleSelect
            id="year"
            value={selectedYear}
            options={yearOptions}
            placeHolder={`${t("activityList.year")}`}
            onChange={handleYearSelection}
          />
        </div>
        {selectedYear &&
          (selectedYearActivities.length > 0 ? (
            <>
              {selectedYearActivities.map((item) => (
                <Activity
                  key={item.activityId}
                  act={item}
                  userData={userData}
                  userId={userId}
                />
              ))}
            </>
          ) : (
            <div className="no-data-container">
              <img src={noDataIcon} alt="no data found" />
              {t("activityList.noResult")}
            </div>
          ))}
      </div>
    </div>
  );
};

export default RegisteredActivities;
