import { useTranslation } from "react-i18next";

function SwimmingOptions() {
  const { t } = useTranslation();
  const swimmingOptions = [
    {
      label: t("childProfile.deepWater"),
      value: "Strong - deep water",
    },
    {
      label: t("childProfile.shoulderHigh"),
      value: "Moderate – shoulder high",
    },
    {
      label: t("childProfile.waistHigh"),
      value: "Weak – waist high",
    },
    {
      label: t("childProfile.lifeJacket"),
      value: "Life Jacket",
    },
    {
      label: t("childProfile.noSwimming"),
      value: "No Swimming",
    },
  ];

  return swimmingOptions;
}

export default SwimmingOptions;
