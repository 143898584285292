import { createContext, useContext } from "react";

// app context
const AppContext = createContext({
  entryPoint: null, // super / center / payment
  superId: null, // valid string when entry point is super
  centerId: null, // valid string when entry point is super or center
  paymentInfo: {}, // valid object entry point is payment

  userData: {}, // user data object

  showLoading: null, // function for global loading
  showNotification: null, // function for global notification
  updateCenterId: null, // function for updating center id
  updateUserData: null, // function for updating user data
});

// hook to get auth context
const useAppContext = () => useContext(AppContext);

export { AppContext, useAppContext };
