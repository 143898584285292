import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// context and routes
import { useAppContext } from "../../context/AppContext";

// common components
import SingleSelect from "../../components/input-fields/SingleSelect";

// utils
import { getDateRange, roundNumber } from "../../utils/tools";
import { activityStatus } from "../../utils/activityStatus";

// images
import capacityBlue from "../../assets/icons/capacity-blue.png";
import capacityWhite from "../../assets/icons/capacity-white.png";
import personIcon from "../../assets/icons/capacity-white.png";

// scss
import "../../scss/views/Registration/AgeGroupSelection.scss";

// age group selection page
function AgeGroupSelection({ activity, campConfig, setCampConfig }) {
  const { t } = useTranslation();
  const { userData, showNotification } = useAppContext();

  // activity status
  const activityStatusType = activityStatus(activity); // status-join / status-waiting

  // age groups in the activity
  const ageGroups = activity.info.ageGroups.map((ageGroup) => {
    return {
      label: ageGroup.program,
      value: ageGroup.program,
      sessionRate: ageGroup.sessionRate,
      dailyRate: ageGroup.dailyRate || 0,
    };
  });

  // check daily session
  const isDailySession = Boolean(activity.info.dailySession);

  // camp configuration
  const [childId, setChildId] = useState(campConfig.childId || "");
  const [childName, setChildName] = useState(campConfig.childName || "");
  const [ageGroup, setAgeGroup] = useState(campConfig.ageGroup || "");
  const [dateOptions, setDateOptions] = useState(campConfig.dateOptions || []);
  const [dailyRate, setDailyRate] = useState(
    campConfig.dailyRate >= 0 ? campConfig.dailyRate : "--"
  );
  const [sessionRate, setSessionRate] = useState(
    campConfig.sessionRate >= 0 ? campConfig.sessionRate : "--"
  );
  const [totalFee, setTotalFee] = useState(
    campConfig.total >= 0 ? campConfig.total : "--"
  );

  // update camp configuration
  useEffect(() => {
    // days bit map
    let days = "";
    dateOptions.forEach((d) => {
      days += d.active ? "1" : "0";
    });

    // update campConfig
    setCampConfig({
      childId: childId,
      childName: childName,
      ageGroup: ageGroup,
      dateOptions: dateOptions,
      days: days,
      dailyRate,
      sessionRate,
      total: totalFee,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childId, ageGroup, dateOptions, dailyRate, sessionRate, totalFee]);

  useEffect(() => {
    const initDateList = () => {
      const registrationSlots = [...activity.registers];
      const registrationCapacity = activity?.info?.capacity;
      const waitingCapacity = activity?.info?.waiting;
      const waitings = activity?.waitings;

      const dateRange = getDateRange(activity?.fromDate, activity?.toDate);
      const month1 = dateRange.split(" - ")[0]?.split(" ")[0];
      const month2 = dateRange.split(" - ")[1]?.split(" ")[0];
      const day1 = dateRange.split(" - ")[0]?.split(" ")[1];
      const day2 = dateRange.split(" - ")[1]?.split(" ")[1];

      let count = 0;

      let disableDateOption = false;

      let fullSlotCount = 0; // count the number of full slots
      for (const register of registrationSlots) {
        if (register >= registrationCapacity) {
          fullSlotCount++;
        }
      }

      // disable date option if all slots are full
      if (
        fullSlotCount >= registrationSlots.length &&
        waitings < waitingCapacity
      ) {
        disableDateOption = true;
      }

      const newDateList = [];

      for (let index = 0; index < registrationSlots.length; index++) {
        const tempDateOption = {
          date: "",
          disable: false,
          value: 0,
          active: false,
        };

        // set date option date
        let i = Number(day2) - index;
        if (i <= 0) {
          i = Number(day1) - count + registrationSlots.length - 1;
          tempDateOption.date = month1 + " " + i;
          count++;
        } else {
          count++;
          tempDateOption.date = month2 + " " + i;
        }

        // set date option capacity
        const _value =
          registrationCapacity -
          registrationSlots[registrationSlots.length - index - 1];
        if (_value < 0) {
          tempDateOption.value = 0;
        } else {
          tempDateOption.value = _value;
        }

        // disable date options
        if (!disableDateOption && tempDateOption.value === 0) {
          tempDateOption.value = 0;
          tempDateOption.disable = true;
        }

        newDateList.unshift(tempDateOption);
      }

      setDateOptions([...newDateList]);
    };

    if (isDailySession && !campConfig.dateOptions) {
      initDateList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --- child selection ---
  const handleSelectChild = (selected) => {
    const sChild = userData.children.find((c) => c.childId === selected);
    if (sChild.formIncomplete) {
      showNotification(
        "warning",
        t("registration.formIncompleteTitle"),
        t("registration.formIncompleteMessage")
      );
      return;
    }

    setChildId(sChild.childId);
    setChildName(sChild.name);
  };

  // --- age group selection ---
  const handleSelectAgeGroup = (selected) => {
    const selectedAgeGroup = ageGroups.find((group) => {
      return group.value === selected;
    });

    // update age group
    setAgeGroup(selected);

    let total = 0;
    if (isDailySession) {
      // update daily rate and session rate
      setDailyRate(roundNumber(selectedAgeGroup?.dailyRate));
      setSessionRate(roundNumber(selectedAgeGroup?.sessionRate));

      // selected days
      let days = 0;
      dateOptions.forEach((item) => {
        if (item.active) {
          days++;
        }
      });

      if (activity.registers.length === days) {
        total = roundNumber(selectedAgeGroup?.sessionRate);
      } else {
        total = roundNumber(selectedAgeGroup?.dailyRate * days);
      }
    } else {
      setSessionRate(roundNumber(selectedAgeGroup?.sessionRate));
      total = roundNumber(selectedAgeGroup?.sessionRate);
    }

    // update total fee
    setTotalFee(total);
  };

  // --- age date selection---
  const handleDateOptionSelect = (index) => {
    const newDateOptions = [...dateOptions];
    newDateOptions[index].active = !newDateOptions[index].active;

    let selectedDateSlots = 0;
    newDateOptions.forEach((item) => {
      if (item.active) {
        selectedDateSlots++;
      }
    });

    // total fee
    if (activity.registers?.length === selectedDateSlots) {
      setTotalFee(roundNumber(sessionRate)); // total fee is session rate when all days are selected
    } else {
      setTotalFee(roundNumber(dailyRate * selectedDateSlots)); // calculate total fee according to selected days
    }

    setDateOptions([...newDateOptions]);
  };

  // render page
  return (
    <div className="age-group-selection">
      <label>{t("registration.registerFor")}</label>
      <span className="noChildText">
        {userData.children.length === 0 && t("registration.noChild")}
      </span>
      <SingleSelect
        value={childId}
        options={userData.children.map((c) => {
          return { label: c.name, value: c.childId };
        })}
        onChange={handleSelectChild}
        placeHolder={t("registration.pleaseSelectAgeGroup")}
      />
      <label>{t("registration.selectAgeGroup")}</label>
      <SingleSelect
        value={ageGroup}
        options={ageGroups}
        onChange={handleSelectAgeGroup}
        placeHolder={t("registration.pleaseSelectAgeGroup")}
      />
      {isDailySession ? (
        <>
          <label>{t("registration.selectTheDate")}</label>
          <div className="date-selections">
            {dateOptions.map((dateItem, index) => {
              return (
                <div
                  key={`date-option-${index}`}
                  className={`option ${
                    dateItem.active
                      ? "active"
                      : dateItem.disable
                      ? "disable"
                      : ""
                  }`}
                  onClick={() =>
                    !dateItem.disable ? handleDateOptionSelect(index) : null
                  }
                >
                  <div className="date">{dateItem.date}</div>
                  <div className="capacity">
                    <img
                      src={
                        dateItem.active
                          ? capacityWhite
                          : dateItem.disable
                          ? capacityWhite
                          : capacityBlue
                      }
                      alt=""
                    />
                    {dateItem.value}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <div className="remaining-space">
          <label>
            {activityStatusType === "status-join"
              ? t("registration.remainingActivitySpace")
              : t("registration.remainingWaitingSpace")}
          </label>
          <div className={`remaining-space-number ${activityStatusType}`}>
            <img src={personIcon} alt={activity?.info.capacity} />
            <span>
              {activityStatusType === "status-join"
                ? activity.info.capacity - activity.registers
                : activity.info.waiting - activity.waitings}
            </span>
          </div>
        </div>
      )}
      <label>{t("registration.fees")}</label>
      <div className="fees">
        <div className="fees__summary">
          {isDailySession && (
            <label className="daily-rate">{`${t(
              "registration.dailyRate"
            )}: ${`$${dailyRate}/${t("registration.day")}`}`}</label>
          )}
          <label>{`${t(
            "registration.sessionRate"
          )}: ${`$${sessionRate}`}`}</label>
        </div>
        <div className="fees__total">
          {t("registration.total")}: <span>{`$${totalFee}`}</span>
        </div>
      </div>
    </div>
  );
}

export default AgeGroupSelection;
