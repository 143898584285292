import React from "react";

import "../../scss/components/button/GreyButton.scss";

/**
 * @return{React.Component}
 */
function GreyButton({ onClick, className, children }) {
  return (
    <button
      className={`grey-button ${className ? className : ""}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export default GreyButton;
