import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// context and routes
import { useAppContext } from "../../context/AppContext";
import routes from "../../context/routes";

// common components
import BackLine from "../../components/backLine/BackLine";

// sub-components
import ActivityInfo from "./ActivityInfo";
import Disclaimer from "./Disclaimer";
import AgeGroupSelection from "./AgeGroupSelection";
import Confirmation from "./Confirmation";

// style
import "../../scss/views/Registration/Registration.scss";

// activity registration page
function Registration() {
  const { t } = useTranslation();

  const { userData } = useAppContext();
  const { selectedActivity, centerInfo } = userData;

  // show confirmation page
  const [campConfig, setCampConfig] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);

  // check if display pre-registration info
  const isShowPreregistrationInfo = () => {
    // pre-registration is not enabled
    if (!selectedActivity.info.resDate) {
      return 0; // no public access
    }

    // pre-registration is enabled (check the date and time)
    const resDate = selectedActivity.info.resDate;
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    const day = new Date().getDate();
    const today = `${year}-${month > 9 ? month : "0" + month}-${
      day > 9 ? day : "0" + day
    }`;
    if (resDate < today) return null; // allow public access
    if (resDate > today) return 1; // no public access yet

    const hour = new Date().getHours();
    if (hour < 9) return 1; // no public access yet
    if (hour >= 9) return null; // allow public access
  };

  // check if age group and date selection (daily session only) are completed
  const isDisableNextButton = () => {
    // check selected age group
    if (!campConfig.childId || !campConfig.ageGroup) {
      return true;
    }

    // check selected date(s) for daily session
    const isDailySession = Boolean(selectedActivity.info.dailySession);
    if (isDailySession) {
      if (!campConfig.days || !campConfig.days.includes("1")) {
        return true;
      }
    }

    // enable the button
    return false;
  };

  // activity registration page
  return (
    <div className="Registration">
      {!showConfirmation ? (
        <>
          <BackLine route={routes.ACTIVITY_LIST} />
          <h2 className="centerName">{centerInfo.name}</h2>
          <ActivityInfo
            activity={selectedActivity}
            goNext={() => {
              setShowConfirmation(true);
            }}
            isDisableNextButton={isDisableNextButton()}
          />
          {isShowPreregistrationInfo() === 0 ? (
            <div className="pre-registration">
              {t("registration.preRegistration0")}
            </div>
          ) : isShowPreregistrationInfo() === 1 ? (
            <div className="pre-registration">
              {`${t("registration.preRegistration1")} ${
                selectedActivity.info.resDate
              }`}
            </div>
          ) : null}
          <div className="main-content">
            <div className="main-content__left">
              <AgeGroupSelection
                activity={selectedActivity}
                campConfig={campConfig}
                setCampConfig={setCampConfig}
              />
            </div>

            <div className="main-content__right">
              <div className="main-content__right__content">
                {selectedActivity.info.disclaimer?.length > 0 ? (
                  <Disclaimer message={selectedActivity.info.disclaimer} />
                ) : (
                  t("registration.noDisclaimer")
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <Confirmation
          campConfig={campConfig}
          setShowConfirmation={setShowConfirmation}
        />
      )}
    </div>
  );
}

export default Registration;
