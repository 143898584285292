const ChildProfile = {
  selectOrAddChildPlace: "Click here to select or add child",
  addAnotherChild: "Add Another Child",
  childInformation: "Child Information",
  childName: "Child Name",
  dateOfBirth: "Date of Birth",
  gender: "Gender",
  genderPlaceholder: "Please select gender",
  address: "Address",
  city: "City",
  province: "Province",
  provincePlaceholder: "Please select province",
  postalCode: "Postal Code",
  doctorName: "Doctor Name",
  provideDetails: "Please provide details",
  disabilities: "Disabilities",
  allergies: "Allergies",
  medicalConditions: "Medical Conditions",
  specialNeeds: "Special Needs",
  careCard: "Care Card",
  pickupList: "Pickup List",
  name: "Name",
  phoneNumber: "Phone Number",
  addPickUpList: "Add Pickup List",
  deepWater: "Strong - deep water",
  shoulderHigh: "Moderate – shoulder high",
  waistHigh: "Weak – waist high",
  lifeJacket: "Life Jacket",
  noSwimming: "No Swimming",
  yes: "Yes",
  no: "No",
  swimmingTitle: "Please select your child’s capacity for swimming.",
  swimmingPlace: "Please select the level",
  consents: "Consents",
  yesOrNo: "Yes/No",
  consent_0:
    "1. I confirm that my child has had experiences away from home and in a childcare facility.",
  consent_1: "2. I permit this center to apply sunscreen on my child.",
  consent_2: "3. I permit this center to take my child on outings.",
  consent_3: "4. I give consent to this center to photograph my child.",
  consent_4: "5. There are individuals prohibited from accessing my child.",
  consent_4Add: "Please provide documents to this center.",
  consent_5: "6. My child has been immunized.",
  consent_5Add:
    "Please provide an updated copy of their immunization records before your child attends.",
  male: "Male",
  female: "Female",
  other: "Other",
  unknown: "Unknown",
  submit: "Submit",
  cancel: "Cancel",
  savedTitle: "Saved",
  savedMessage: "Child information has been saved.",
  childPhoto: "Child Photo*",
  uploadButton: "+ Upload",
  photoValidation: "Photo is required",
  wrongPhotoTitle: "Wrong Photo Format",
  wrongPhotoMessage: "Only JPG & JPEG format are allowed",
};

export default ChildProfile;
