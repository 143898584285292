const login = {
  ok: "OK",
  next: "Next",
  confirm: "Confirm",
  systemTitle: "System Not Available",
  systemMessage:
    "The system is not available temporarily, please try again later.",
  networkTitle: "Network Error",
  networkMessage: "Please check your network connection and try again.",
  expiredTitle: "Session Expired",
  expiredMessage: "The session has expired, please login again.",
  language: "Français",
  userAccount: "User Account",
  activityList: "Activity List",
  login: "Login",
  logout: "Log out",
  requiredField: "This field is required",
  invalidEmail: "Invalid email",
  invalidPhone: "Invalid phone number",
  invalidCode: "Invalid verification code",
  selectedDates: "Selected Date(s)",
  congratulations: "Congratulations! You only have one step left!",
  transactionFee: "Platform Service Fee",
  paymentSession: "Session",
  total: "Total",
  continue: "Continue",
};

export default login;
