const paymentInfo = {
  congratulations: "Une seule étape vous reste!",
  success: "Félicitations, votre enfant est inscrit avec succès!",
  invalidLink: "Le lien de paiement est invalide.",
  invalidLinkHint:
    "Vous avez peut-être déjà effectué le paiement et enregistré l'activité avec succès",
  selectedDays: "Jours sélectionnés",
  transactionFee: "Frais de Service de Plateforme",
  activityFee: "Frais d'activité",
  total: "Total",
  pay: "Confirmer & Payer",
  available: "Disponible",
  makePayment: "Paiement",
  registrationSuccessful: "Succès",
};

export default paymentInfo;
