const ActivityList = {
  centerActivityList: "Activity List",
  noResult: "No Result",
  open: "Open",
  waiting: "Waiting",
  full: "Full",
  campInfo: "Activity Information",
  campTime: "Activity Time",
  session: "Session",
  capacity: "Capacity",
  status: "Status",
  register: "Register",
  registrationHistory: "Registration History",
  registerMore: "Register More",
  year: "Year",
};
export default ActivityList;
