const RegisteredActivities = {
  registered: "Registered",
  waiting: "Waiting",
  registerMore: "Register More",
  center: "Centre",
  total: "Total",
  invoice: "Invoice",
  downloadPDF: "Download",
  currentYearHeading: "Registered activities since",
  previousYearHeading: "Activity registered in previous years",
  PDFHeader: "INVOICE",
  PDFdate: "Date:",
  PDFinvoiceTo: "Invoice To:",
  PDFactivityDetails: "Activity Details",
  PDFsession: "Session",
  PDFdaily: "Daily",
  PDFfee: "Activity Fee",
  PDFtrans: "Service Fee",
  PDFtotal: "Paid",
};
export default RegisteredActivities;
