const Registration = {
  preRegistration0: "* The activity is not open to public",
  preRegistration1: "* The activity will be open to public at 9:00AM on",
  joinTheSession: "Register",
  addToWaitingList: "Join Waitlist",
  sessionFull: "Session Full",
  capacity: "Capacity",
  activityCapacity: "Activity Capacity",
  waitingCapacity: "Waiting Capacity",
  day: "Day",
  dailyRate: "Daily Rate",
  sessionRate: "Session Rate",
  total: "Total",
  selectAgeGroup: "Select Age Group",
  pleaseSelectAgeGroup: "Please select the age group",
  selectTheDate: "Select Date(s)",
  remainingActivitySpace: "Remaining Activity Space",
  remainingWaitingSpace: "Remaining Waiting Space",
  fees: "Fees",
  activityDisclaimer: "Activity Disclaimer",
  disclaimerTitle: "Disclaimer",
  noDisclaimer: "No Disclaimer",
  confirmationTitle: "Confirm Your Registration",
  registerFor: "Register for ",
  formIncompleteTitle: "Form Incomplete",
  formIncompleteMessage: "Please complete the child form in 'User Account'.",
  paymentMethod: "Payment Method",
  name: "Cardholder Name",
  cardNumber: "Card Number",
  expiry: "Expiry",
  saveCard:
    "Add this card to your family profile for convenient future transactions.",
  confirmPay: "Confirm & Register",
  registeredTitle: "Congratulations",
  registeredMessage:
    "You registered your child to the activity successfully. The confirmation email has been sent to you.",
  waitingTitle: "Congratulations",
  waitingMessage:
    "You put your child on the waiting list of the activity successfully. The confirmation email has been sent to you.",
  cancel: "Cancel",
  sessionFee: "Session Fee",
  transactionFee: "Platform Service Fee",
  noChild: "Please add child to register for this activity.",
  restrictedTitle: "Not Open",
  restrictedMessage0: "The activity is not open to public",
  restrictedMessage1: "The activity will be open to public at 9:00AM on",
  inListTitle: "Already Registered",
  inListMessage: "The child is in registration or waiting list already.",
  noSpaceTitle: "No Space",
  noSpaceMessage: "There is no space left for the activity now.",
  paymentFailedTitle: "Payment Failed",
  paymentFailedMessage: "The payment was failed due to",
};
export default Registration;
