/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// icons
import upArrowIcon from "../../assets/icons/up-arrow.png";
import downArrowIcon from "../../assets/icons/down-arrow.png";
// scss
import "../../scss/components/input-fields/SingleSelect.scss";

/**
 * This is a custom React hook that triggers a callback function when a user clicks outside of a
 * specified element.
 * @param callback - The callback parameter is a function that will be called when a click event occurs
 * outside of the element referenced by the ref object.
 * @returns The `useOutsideClick` hook returns a `ref` object that can be attached to a DOM element.
 * This `ref` is used to detect clicks outside of the element and trigger the `callback` function
 * passed as an argument to the hook.
 */

const useOutsideClick = (callback) => {
  const ref = useRef();

  React.useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [ref]);

  return ref;
};

/**
 * The SingleSelect function is a customizable dropdown component in JavaScript that allows users to
 * select a single option from a list of options.
 * @returns The SingleSelect component is being returned.
 */

const SingleSelect = ({
  value = "",
  options,
  placeHolder = "",
  onChange,
  maxRows = 5,
  id = "",
  label = "",
  validation,
}) => {
  const { t } = useTranslation(); //locale translation

  // state variables
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [isShowDropdown, setIsShowDropdown] = useState(false);

  const handleClickOutside = () => {
    setIsShowDropdown(false);
  };

  // reference
  const targetRef = useOutsideClick(handleClickOutside);

  // get component width which will be use for dropdown container
  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      });
    }
  }, []);

  // show/hide dropdown
  const handleOnClickArrow = () => {
    setIsShowDropdown(!isShowDropdown);
  };

  /**
   * This function returns the label of an option based on its value or a placeholder if the value is
   * empty.
   * @returns The `getDisplayValue` function returns either the `placeHolder` value if the `value` array
   * is empty, or the `label` value of the option object whose `value` property matches the `value`
   * array.
   */

  const getDisplayValue = () => {
    if (value.length === 0) {
      return placeHolder;
    } else {
      // find the value from the options and return the label
      const selectedOption = options.find((o) => o.value === value);
      return selectedOption ? selectedOption.label : placeHolder;
    }
  };

  /**
   * This function handles the click event on a dropdown option and sets the selected value.
   * @param option - The option parameter is a variable that represents the selected option from a
   * dropdown menu. It is passed as an argument to the handleOnClickOption function.
   */

  const handleOnClickOption = (option) => {
    setIsShowDropdown(false); // hide dropdown
    onChange(option, id); // set select value
  };

  return (
    <div ref={targetRef} className="single-select" onClick={handleOnClickArrow}>
      <div
        className={`input ${validation && !validation.valid ? "invalid" : ""}`}
      >
        {label.length > 0 && <label>{label}</label>}
        <div className={`input__value ${value.length === 0 ? "no-value" : ""}`}>
          {getDisplayValue(value)}
        </div>
        <div className="input__arrow">
          <img
            src={isShowDropdown ? upArrowIcon : downArrowIcon}
            alt="up/down arrow"
          />
        </div>
      </div>
      {validation && !validation.valid && (
        <div className="validation-container">
          <span>{t(validation.message)}</span>
        </div>
      )}

      {isShowDropdown && (
        <div className="dropdown">
          <div
            className="dropdown__options"
            style={{
              width: dimensions.width,
              height:
                options.length < maxRows ? "auto" : maxRows * dimensions.height,
            }}
          >
            {options.map((option) => {
              return (
                <div
                  key={option.value}
                  className="option"
                  onClick={(e) => handleOnClickOption(option.value)}
                >
                  {option.label}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleSelect;
