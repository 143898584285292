const validationTemplate = {
  "1stName": {
    rules: ["required"],
    message: "",
    valid: true,
  },
  "1stPhone": {
    rules: ["required", "phoneNumberFormat"],
    message: "",
    valid: true,
  },
  "1stCell": {
    rules: ["required", "phoneNumberFormat"],
    message: "",
    valid: true,
  },
  "2ndName": {
    rules: ["required"],
    message: "",
    valid: true,
  },
  "2ndEmail": {
    rules: ["emailFormat"],
    message: "",
    valid: true,
  },
  "2ndCell": {
    rules: ["required", "phoneNumberFormat"],
    message: "",
    valid: true,
  },
  emName: {
    rules: ["required"],
    message: "",
    valid: true,
  },
  emCell: {
    rules: ["required", "phoneNumberFormat"],
    message: "",
    valid: true,
  },
  valid: null,
};

export default validationTemplate;
