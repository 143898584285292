/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useTranslation } from "react-i18next";

// common components
import SingleSelect from "../../../components/input-fields/SingleSelect";

// utils
import { deepClone } from "../../../utils/tools";
import YesNoOptions from "../../../utils/definitions/YesNoOptions";
import questions from "../../../utils/definitions/ConsentQuestions";

// scss
import "../../../scss/views/ChildProfile/childForms/ConsentForm.scss";

function ConsentForm({
  childDetails,
  formValidation,
  setFormValidation,
  setChildDetails,
}) {
  const { t } = useTranslation(); // translation

  const yesNoOptions = YesNoOptions();

  const handleOnQuestionYesNoChange = (value, id) => {
    const tempValidation = { ...formValidation };

    // reset formValidation
    tempValidation[id].message = "";
    tempValidation[id].valid = true;
    setFormValidation({ ...tempValidation });

    // set new value
    const tempChildDetails = deepClone(childDetails); // deep copy
    const indexToReplace = id.split("_")[1];
    const consentSplit = tempChildDetails.consent.split(""); // ["0","0","0","0","0","0"]
    consentSplit[indexToReplace] = value; // replace consent using indexToReplace
    tempChildDetails.consent = consentSplit.join(""); // make consent an array again
    setChildDetails(tempChildDetails); // set child details with the new consent
  };

  const DisplayQuestionnaire = () => {
    return (
      <div className="questionnaire">
        <div className="questionnaire__header">
          <label> {t("childProfile.consents")}*</label>
          <label> {t("childProfile.yesOrNo")}</label>
        </div>
        {questions.map((question, index) => (
          <div
            key={question.id}
            className={`question ${
              !formValidation[`consent_${index}`].valid ? "invalid" : ""
            }`}
          >
            <div className="text">
              {t(`childProfile.${question.id}`)}*
              {question.tipKey && childDetails.consent[index] === "1" && (
                <p className="tip">{t(`childProfile.${question.tipKey}`)}</p>
              )}
            </div>

            <SingleSelect
              id={question.id}
              value={
                childDetails.consent[index] === "3"
                  ? ""
                  : childDetails.consent[index]
              }
              options={[...yesNoOptions]}
              onChange={handleOnQuestionYesNoChange}
              placeHolder={"--"}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="consent-form">
      <h3> {t("childProfile.consents")}</h3>
      <DisplayQuestionnaire />
    </div>
  );
}

export default ConsentForm;
