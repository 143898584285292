import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";

// auto context
import { useAuth } from "./AuthContext";

// app routes
import routes from "./routes";

// app pages
import Login from "../views/login/Login";

import UserAccount from "../views/user-account/UserAccount";
import FamilyProfile from "../views/family-profile/FamilyProfile";
import ChildProfile from "../views/child-profile/ChildProfile";
import RegisteredActivities from "../views/registered-activities/RegisteredActivities";

import CenterList from "../views/center-list/CenterList";
import ActivityList from "../views/activity-list/ActivityList";
import Registration from "../views/registration/Registration";

import PaymentInfo from "../views/payment-info/PaymentInfo";

// app components with nav header
import Layout from "../components/layout/Layout";

// public route for camp list
const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

// private route only for authorized user
const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{ pathname: routes.LOGIN_PAGE, search: window.location.search }}
          />
        )
      }
    />
  );
};

// app level routing
function AppRouting({ initRoute }) {
  // routing
  return (
    <Router>
      <Switch>
        <Route exact path={routes.LOGIN_PAGE}>
          <Login />
        </Route>

        <PrivateRoute
          exact
          path={routes.USER_ACCOUNT}
          component={UserAccount}
        />
        <PrivateRoute
          exact
          path={routes.FAMILY_PROFILE}
          component={FamilyProfile}
        />
        <PrivateRoute
          exact
          path={routes.CHILD_PROFILE}
          component={ChildProfile}
        />
        <PrivateRoute
          exact
          path={routes.REGISTERED_ACTIVITIES}
          component={RegisteredActivities}
        />

        <PublicRoute exact path={routes.CENTER_LIST} component={CenterList} />
        <PublicRoute
          exact
          path={routes.ACTIVITY_LIST}
          component={ActivityList}
        />

        <PrivateRoute
          exact
          path={routes.ACTIVITY_REGISTRATION}
          component={Registration}
        />

        <Route exact path={routes.PAYMENT_INFO} component={PaymentInfo} />
        <Route
          path="*"
          render={() => (
            <Redirect
              to={{ pathname: initRoute, search: window.location.search }}
            />
          )}
        />
      </Switch>
    </Router>
  );
}

export default AppRouting;
