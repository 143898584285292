import React from "react";
import { useTranslation } from "react-i18next";

// 404 icon
import icon404 from "../../assets/imgs/404.png";

// styling
import "../../scss/views/Login/login.scss";

// --- invalid URL page ---
const Page404 = () => {
  const { t } = useTranslation();

  // render page
  return (
    <div className="login">
      <div className="outline">
        <img className="logo" alt="logo" src={icon404} />
        <div style={{ fontWeight: 700, color: "#BEBEBE" }}>
          <div style={{ fontSize: 36 }}>404</div>
          <div style={{ marginTop: 15, fontSize: 20 }}>
            {t("login.page404")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page404;
