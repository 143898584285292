const userAccount = {
  heading: "Bonjour, Parent",
  familyInformation: "Informations familiales",
  familyInformationNote:
    "*Veuillez remplir informations familiales et ajouter votre enfant plus tard.",
  fillFamilyForm: "Remplir le formulaire familial",
  editFamilyForm: "Modifier le formulaire familial",
  childInformation: "Informations sur l'enfant et dossiers d'inscription",
  addChild: "Ajouter un enfant",
  guardian1: "Tuteur 1",
  guardian2: "Tuteur 2",
  emergencyContact: "Contact d'urgence",
  paymentMethod: "Moyen de paiement",
  name: "Nom",
  phone: "Téléphone",
  email: "Email",
  cellPhone: "Téléphone portable",
  creditCard: "Carte de crédit",
  expiry: "Expiration",
  childName: "Nom de l'enfant",
  firstName: "Prénom",
  lastName: "Nom de famille",
  editChildForm: "Modifier formulaire de l'enfant",
  completeChildForm: "Compléter formulaire de l'enfant",
  registrationHistory: "Historique inscriptions",
  registrationInstruction:
    "*Veuillez remplir informations sur l'enfant pour inscrire des activités.",
  childModalHeader: "Informations sur l'enfant",
  childModalSubHeader:
    "*Vous ne pouvez pas supprimer un enfant une fois créé. Assurez-vous que le nom de votre enfant est correct.",
  cancel: "Annuler",
  submit: "Soumettre",
  addedTitle: "Ajouté",
  addedMessage:
    "Veuillez vous assurer que les informations de l’enfant sont complétées avant l’inscription.",
};
export default userAccount;
