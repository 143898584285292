const familyProfile = {
  heading: "Family Information",
  "1stName": "Guardian 1 Name (First Name & Last Name)*",
  "1stPhone": "Home Phone*",
  "1stCell": "Guardian 1 Cell Phone*",
  "2ndName": "Guardian 2 Name (First Name & Last Name)*",
  "2ndCell": "Guardian 2 Cell Phone*",
  "2ndEmail": "Guardian 2 Email (Optional)",
  emName: "Emergency Contact (First Name & Last Name)*",
  emCell: "Emergency Cell Phone*",
  back: "Back",
  cardholderName: "Cardholder Name",
  cardNumber: "Card Number",
  expiry: "Expiry",
  CVV: "CVV",
  paymentMethod: "Payment Method (Optional)",
  submit: "Submit",
  cancel: "Cancel",
  savedTitle: "Saved",
  savedMessage: "family information has been saved.",
  noPaymentMethod:
    "Adding credit card here may save your time for future payments.",
  addCard: "Add Card",
  removeCard: "Remove Card",
  nameIsRequired: "Name is required",
};

export default familyProfile;
