export const deepClone = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

/**
 * @param {String} fromDate
 * @param {String} toDate
 * @return {string}
 */
export function getDateRange(fromDate, toDate) {
  if (!fromDate || !toDate) {
    return "";
  }
  const monthName = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sept",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };
  const date1 = fromDate.split("-");
  const date2 = toDate.split("-");
  const month1 = monthName[Number(date1[1])];
  const month2 = monthName[Number(date2[1])];
  const startDay = Number(date1[2]);
  const endDay = Number(date2[2]);
  return month1 + " " + startDay + " - " + month2 + " " + endDay;
}

export function formatDateMonth(aDate) {
  const tDate = aDate.split("-");

  if (tDate.length !== 3) {
    return "";
  }

  const monthName = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sept",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  const month = monthName[Number(tDate[1])];

  return `${month} ${tDate[2]},  ${tDate[0]}`;
}

/**
 * for centerId decryption
 * @param {Date<string>} salt
 * @param {string} encoded
 * @return {*}
 */
export function idDecrypt(salt, encoded) {
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const applySaltToChar = (code) =>
    textToChars(salt).reduce((a, b) => a ^ b, code);

  return encoded
    .match(/.{1,2}/g)
    .map((hex) => parseInt(hex, 16))
    .map(applySaltToChar)
    .map((charCode) => String.fromCharCode(charCode))
    .join("");
}

/**
 * @param {Number} n
 * @return {number}
 */
export function roundNumber(n) {
  // round to 2-decimal places
  return Math.round(n * 100 + Number.EPSILON) / 100;
}

export const serviceCharge = (sessionAmount, calcTrans, sRate) => {
  // make sure the session amount is positive
  if (sessionAmount <= 0) {
    return 0;
  } else if (!calcTrans) {
    return 0;
  } else {
    return (
      Math.round(
        (totalCharge(sessionAmount, calcTrans, sRate) - sessionAmount) * 100 +
          Number.EPSILON
      ) / 100
    );
  }
};

// calculate total charge including transaction fee
export const totalCharge = (sessionAmount, calcTrans, sRate) => {
  // stripe portion in addition to rate
  const STRIPECENTS = 0.3; // 30 cents

  // make sure the session amount is positive
  if (sessionAmount <= 0) {
    return 0;
  } else if (!calcTrans) {
    return sessionAmount;
  } else {
    return (
      Math.round(
        ((sessionAmount + STRIPECENTS) / (1 - sRate)) * 100 + Number.EPSILON
      ) / 100
    );
  }
};

export const getActiveDates = (days, fromDate, toDate) => {
  const _length = days?.length;
  if (!fromDate || !toDate) {
    return "";
  }
  const activeDate = [];
  const monthName = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sept",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };
  const date1 = fromDate.split("-");
  const date2 = toDate.split("-");
  const month1 = monthName[Number(date1[1])];
  const month2 = monthName[Number(date2[1])];
  const startDay = Number(date1[2]);
  const endDay = Number(date2[2]);

  for (let index = 0; index < _length; index++) {
    let count = 0;
    let i = Number(endDay) - index;
    if (days[_length - index - 1] === "1") {
      if (i <= 0) {
        i = Number(startDay) - count + _length - 1;
        activeDate.unshift(month1 + " " + i);
        count++;
      } else {
        count++;
        activeDate.unshift(month2 + " " + i);
      }
    }
  }

  return activeDate.join(", ");
};
