const login = {
  ok: "OK",
  next: "Suivant",
  confirm: "Confirmer",
  systemTitle: "Système non disponible",
  systemMessage:
    "Le système n'est pas disponible temporairement, veuillez réessayer plus tard.",
  networkTitle: "Erreur réseau",
  networkMessage: "Veuillez vérifier votre connexion réseau et réessayer.",
  expiredTitle: "Session expirée",
  expiredMessage: "La session a expiré, veuillez vous connecter à nouveau.",
  language: "English",
  userAccount: "Compte utilisateur",
  activityList: "Liste d'activités",
  login: "Connexion",
  logout: "Déconnexion",
  requiredField: "Ce champ est obligatoire",
  invalidEmail: "Email invalide",
  invalidPhone: "Téléphone numéro invalide",
  invalidCode: "Vérification code invalide",
  selectedDates: "Dates sélectionnées",
  congratulations: "Félicitations! Il ne vous reste qu'une étape!",
  transactionFee: "Frais de Service de Plateforme",
  paymentSession: "Paiement session",
  total: "Total",
  continue: "Continuer",
};

export default login;
