import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import backIcon from "../../assets/icons/back-icon.png";

import "../../scss/components/back/BackLine.scss";

function BackLine({ route, backBtn = true, rightBtn }) {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  return (
    <div className="back-line">
      {backBtn && (
        <>
          <div
            onClick={() =>
              history.push({ pathname: route, search: location.search })
            }
            className="back-button"
          >
            <img
              alt={t("familyProfile.back")}
              className="back-icon"
              src={backIcon}
            />
            <span className="back-title">{t("familyProfile.back")}</span>
          </div>
        </>
      )}
      {rightBtn && <div className="right-btn">{rightBtn}</div>}
    </div>
  );
}

export default BackLine;
