import React from "react";
import { useTranslation } from "react-i18next";

// app context
import { useAuth } from "../../context/AuthContext";
import { useAppContext } from "../../context/AppContext";

// icon images
import imgSuccess from "../../assets/imgs/success.png";
import imgWarning from "../../assets/imgs/warning.png";
import imgSystem from "../../assets/imgs/system.png";
import imgNetwork from "../../assets/imgs/network.png";

// style
import "../../scss/components/notification.scss";

// pop up notification
const Notification = ({ notification }) => {
  const { t } = useTranslation();

  const { logout } = useAuth();
  const { showNotification } = useAppContext();

  // close pop up
  const handleOk = () => {
    if (notification.callback) {
      // call back
      notification.callback();
    } else if (notification.type === "expired") {
      // back to login
      logout();
    }

    // dismiss the pop-up
    showNotification(null);
  };

  // notification title and message
  let title = null;
  let message = null;
  switch (notification.type) {
    case "system":
      title = t("common.systemTitle");
      message = t("common.systemMessage");
      break;

    case "network":
      title = t("common.networkTitle");
      message = t("common.networkMessage");
      break;

    case "expired":
      title = t("common.expiredTitle");
      message = t("common.expiredMessage");
      break;

    default:
      title = notification.title;
      message = notification.message;
  }

  // render pop up notification
  return (
    <div className="notification">
      <div className="notification-center">
        <img
          alt="notification icon"
          src={
            notification.type === "success"
              ? imgSuccess
              : notification.type === "expired" ||
                notification.type === "warning"
              ? imgWarning
              : notification.type === "system"
              ? imgSystem
              : notification.type === "network"
              ? imgNetwork
              : null
          }
        />
        <div className="title">{title}</div>
        <div className="message">{message}</div>
        <div className="button" onClick={handleOk}>
          {t("common.ok")}
        </div>
      </div>
      <div className="mask" />
    </div>
  );
};

export default Notification;
