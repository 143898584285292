import React, { useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// context and routes
import { useAuth } from "../../context/AuthContext";
import { useAppContext } from "../../context/AppContext";
import routes from "../../context/routes";

// backend service
import api from "../../services/api";
import request from "../../services/Request";

// components
import BackLine from "../../components/backLine/BackLine";
import FamilyForm from "./FamilyForm";
import PaymentForm from "../../components/payment/PaymentForm";

// import PaymentForm from "../../views/family-profile/familyForms/PaymentForm";
import BlueButton from "../../components/buttons/BlueButton";
import GreyButton from "../../components/buttons/GreyButton";

// stripe utils
import { loadStripe } from "@stripe/stripe-js";

// utils
import { validate } from "../../utils/formValidation";
import validationTemplate from "../../utils/definitions/ValidationTemplateFamily";

// scss
import "../../scss/views/FamilyProfile/FamilyInformation.scss";

// family profile page
function FamilyProfile() {
  const creditCardRef = useRef(); // credit card ref

  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const { userId, jwtToken } = useAuth();
  const { showLoading, showNotification, userData } = useAppContext();

  const [familyInfo, setFamilyInfo] = useState(userData.familyInfo || {});
  const [formValidation, setFormValidation] = useState(validationTemplate);

  const [familyPayment, setFamilyPayment] = useState(userData.familyPayment);
  const [addCard, setAddCard] = useState(Boolean(userData.familyPayment));

  const [cardholderName, setCardholderName] = useState("");
  const [validName, setValidName] = useState(true);
  const [isMounted, setIsMounted] = useState(false); // tracks if the component is mounted or not
  const [stripeObject, setStripeObject] = useState(null);

  // Check if the familyInfo object has all the required properties
  const requiredProperties = [
    "1stName",
    "1stPhone",
    "1stCell",
    "2ndName",
    "2ndEmail",
    "2ndCell",
    "emName",
    "emCell",
  ];
  requiredProperties.forEach((property) => {
    if (!familyInfo.hasOwnProperty(property)) {
      familyInfo[property] = "";
    }
  });

  // reordering the familyInfo object according to the sequence of the requiredProperties
  const orderedFamilyInfo = {};
  requiredProperties.forEach((property) => {
    orderedFamilyInfo[property] = familyInfo[property];
  });

  useEffect(() => {
    setFamilyInfo(orderedFamilyInfo);
    setIsMounted(true);
    // eslint-disable-next-line
  }, []);

  // ----load stripe object only ONCE! -----
  useEffect(() => {
    const fetchStripeObject = async () => {
      const res = await loadStripe(
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
      );
      setStripeObject(res);
    };

    // perform fetchStripeObject only when component is mounted to prevent memory leak warning
    if (isMounted) {
      fetchStripeObject(); // cal fetchStripeObject
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);
  // ----load stripe object only ONCE! -----

  // back to user account
  const goBack = () => {
    history.push({ pathname: routes.USER_ACCOUNT, search: location.search });
  };

  // save family info
  const submitFamilyInfo = async () => {
    // reset validation
    setFormValidation(validationTemplate);

    //set validation values
    Object.keys(familyInfo).forEach((key) => {
      formValidation[key].value = familyInfo[key];
    });

    const tempValidate = validate(formValidation);
    if (!tempValidate.valid) {
      setFormValidation({
        ...tempValidate,
      });
      return;
    }

    // validate card holder name
    if (addCard && !familyPayment) {
      if (!cardholderName) {
        setValidName(false);
        return;
      }
    }

    // send to backend
    try {
      showLoading(true);

      // get credit card payment method
      let method = null;
      if (addCard && !familyPayment) {
        method = await creditCardRef.current.handlePay();
        if (!method) return;
      }

      // update family profile
      const res = await request.put(
        api.familyProfile,
        {
          type: "update-profile",
          userId: userId,
          info: familyInfo,
          orgPayment: userData.familyPayment,
          payment: familyPayment
            ? familyPayment
            : addCard
            ? {
                method: method.id,
                name: cardholderName,
                brand:
                  method.card.brand.charAt(0).toUpperCase() + // capitalized
                  method.card.brand.slice(1),
                card: `**** ${method.card.last4}`,
                expiry: `${method.card.exp_month}/${method.card.exp_year}`,
              }
            : null,
        },
        jwtToken
      );

      switch (res.status) {
        case 200:
          showNotification(
            "success",
            t("familyProfile.savedTitle"),
            t("familyProfile.savedMessage"),
            goBack
          );
          break;

        case 403:
          showNotification("expired");
          break;

        default:
          throw new Error("system-error");
      }
    } catch (e) {
      if (e.message === "system-error") {
        showNotification("system");
      } else {
        showNotification("network");
      }
    } finally {
      showLoading(false);
    }
  };

  return (
    <div className="family-information">
      <BackLine route={routes.USER_ACCOUNT} />
      <div className="family-form-header">{t("familyProfile.heading")}</div>
      <FamilyForm
        formValidation={formValidation}
        setFormValidation={setFormValidation}
        familyInfo={familyInfo}
        setFamilyInfo={setFamilyInfo}
      />

      <div className="familyFormPaymentSection">
        <div className="paymentMethod">
          {t("familyProfile.paymentMethod")}
          <span
            onClick={() => {
              if (addCard) {
                setFamilyPayment(null);
                setAddCard(false);
              } else {
                setAddCard(true);
              }
            }}
          >
            {addCard
              ? t("familyProfile.removeCard")
              : t("familyProfile.addCard")}
          </span>
        </div>
        {addCard ? (
          familyPayment ? (
            <div className="card-details">
              <div className="card-details__cardholderName">
                <label>{t("familyProfile.cardholderName")}:</label>
                <p>{familyPayment.name}</p>
              </div>
              <div className="card-details__section2">
                <div className="card-details__section2__cardNumber">
                  <label>
                    {familyPayment.brand} {t("familyProfile.cardNumber")}:
                  </label>
                  <p>{familyPayment.card}</p>
                </div>
                <div className="card-details__section2__expiry">
                  <label>{t("familyProfile.expiry")}:</label>
                  <p>{familyPayment.expiry}</p>
                </div>
              </div>
            </div>
          ) : (
            <PaymentForm
              name={cardholderName}
              setName={(n) => {
                setValidName(true);
                setCardholderName(n);
              }}
              stripeObject={stripeObject}
              creditCardRef={creditCardRef}
              validation={{
                valid: validName,
                message: t("familyProfile.nameIsRequired"),
              }}
            />
          )
        ) : (
          <div>{t("familyProfile.noPaymentMethod")}</div>
        )}
      </div>

      <div className="family-form-footer">
        <GreyButton
          className="cancel-button"
          onClick={() => {
            history.push({
              pathname: routes.USER_ACCOUNT,
              search: location.search,
            });
          }}
        >
          {t("familyProfile.cancel")}
        </GreyButton>
        <BlueButton className="submit-button" onClick={submitFamilyInfo}>
          {t("familyProfile.submit")}
        </BlueButton>
      </div>
    </div>
  );
}

export default FamilyProfile;
