import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// app context
import { useAppContext } from "../../context/AppContext";

// backend service
import api from "../../services/api";
import request from "../../services/Request";

// utils
import { validEmail } from "../../utils/validation";

// styling
import "../../scss/views/Login/login.scss";

// email page
const Email = ({ email, setEmail, changeView }) => {
  const { t } = useTranslation();
  const { showNotification } = useAppContext();

  const [loading, setLoading] = useState(false); // loading button
  const [error, setError] = useState(null); // error message

  // handle continue
  const handleContinue = async () => {
    // email address
    const email = document.getElementById("user-email").value;

    // validation
    if (!validEmail.test(email)) {
      setError(t("login.invalidEmail"));
      return;
    }

    // send to backend
    try {
      setLoading(true);
      const res = await request.post(api.login, {
        email: email,
      });

      switch (res.status) {
        case 200:
          setEmail(email);
          changeView(res.data.nextStep); // password or access-code
          break;

        default:
          throw new Error("system-error");
      }
    } catch (e) {
      if (e.message === "system-error") {
        showNotification("system");
      } else {
        showNotification("network");
      }
    } finally {
      setLoading(false);
    }
  };

  // render email page
  return (
    <>
      <div className="title">{t("login.signIn")}</div>
      <div className="content">{t("login.signInDes")}</div>

      <div className="label">{t("login.email")}</div>
      <input
        id="user-email"
        className="input-box"
        type="text"
        placeholder={t("login.emailPlaceholder")}
        defaultValue={email}
        onChange={() => setError(null)}
        spellCheck="false"
        disabled={loading}
      />
      <div className="error-message">{error}</div>

      <div className="continue-button" onClick={handleContinue}>
        {!loading ? t("common.next") : <div className="loading"></div>}
      </div>
    </>
  );
};

export default Email;
