const ActivityList = {
  centerActivityList: "Liste d'activités",
  noResult: "Aucun résultat",
  open: "Ouvert",
  waiting: "En attente",
  full: "Complet",
  campInfo: "Info Activité",
  campTime: "Horaire Activité",
  session: "Session",
  capacity: "Capacité",
  status: "Statut",
  register: "Inscrire",
  registrationHistory: "Historique Inscriptions",
  registerMore: "Inscrire Plus",
  year: "Année",
};
export default ActivityList;
