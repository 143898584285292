// backend base API path
const baseURL = process.env.REACT_APP_BASE_URL;

// backend apis
const api = {
  login: `${baseURL}/auth/login`, // POST request to login (existing user) or sign up new user
  auth: `${baseURL}/auth/user`, // POST request to authenticate user via email and password or access code
  familyProfile: `${baseURL}/family/profile`, // GET / PUT request to retrieve / update family profile (including password update)
  childProfile: `${baseURL}/child/profile`, // GET / PUT request to retrieve / update child profile
  childPhoto: `${baseURL}/child/photo`, // POST request to upload child photo
  registeredActivities: `${baseURL}/child/registered`, // GET request to retrieve child registered activities
  centerList: `${baseURL}/organization/center/list`, // GET request to retrieve center list in specific organization
  activityList: `${baseURL}/organization/center/activities`, // GET request to retrieve activity list in specific center
  registration: `${baseURL}/enrollment/registration`, // POST request to register to an activity
  waiting: `${baseURL}/enrollment/waiting`, // POST request to add child to an activity waiting list
  waitingPayment: `${baseURL}/enrollment/waiting/payment`, // GET / PUT request to retrieve waiting info / register with payment
};

export default api;
