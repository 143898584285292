import React, { useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// app context
import { useAuth } from "../../context/AuthContext";
import { useAppContext } from "../../context/AppContext";
import routes from "../../context/routes";

// components
import BlueButton from "../buttons/BlueButton";

// icons
import userIcon from "../../assets/icons/userIcon.png";
import listIcon from "../../assets/icons/listIcon.png";
import languageIcon from "../../assets/icons/languageIcon.png";

import "../../scss/components/layout/navbar.scss";

function Navbar() {
  const location = useLocation();
  const history = useHistory();

  const { t, i18n } = useTranslation();
  const { isAuthenticated, logout } = useAuth();
  const { superId, centerId, entryPoint } = useAppContext();

  const [showDropDownMenu, setShowDropDownMenu] = useState(false);

  // logo url
  const logoUrl = superId
    ? `${process.env.REACT_APP_S3_PATH}/super/${superId}/logo.png`
    : `${process.env.REACT_APP_S3_PATH}/${centerId}/logo.png`;

  const toggleMenu = () => {
    setShowDropDownMenu(!showDropDownMenu);
  };

  const languageSwitcher = () => {
    const currentLanguage = localStorage.getItem("_language") || "en";
    if (currentLanguage === "en") {
      i18n.changeLanguage("fr");
      localStorage.setItem("_language", "fr");
    } else {
      i18n.changeLanguage("en");
      localStorage.setItem("_language", "en");
    }
  };

  const handleLogout = () => {
    logout();
    history.replace({ pathname: routes.LOGIN_PAGE, search: location.search });
  };

  return (
    <nav className="navbar">
      <div className="nav-items-left">
        <NavLink
          to={{ pathname: routes.USER_ACCOUNT, search: location.search }}
          className="nav-logo"
        >
          <img src={logoUrl} alt="logo" className="nav-logo-img" />
        </NavLink>
        <div className="nav-pages">
          <NavLink
            to={{ pathname: routes.USER_ACCOUNT, search: location.search }}
            className="nav-item"
            activeClassName="activePage"
            exact
          >
            {t("common.userAccount")}
          </NavLink>
          <NavLink
            to={{
              pathname:
                entryPoint === "super"
                  ? routes.CENTER_LIST
                  : routes.ACTIVITY_LIST,
              search: location.search,
            }}
            isActive={(match, location) =>
              match ||
              location.pathname === routes.CENTER_LIST ||
              (entryPoint === "super" &&
                location.pathname === routes.ACTIVITY_LIST)
            }
            className="nav-item"
            activeClassName="activePage"
            exact
          >
            {t("common.activityList")}
          </NavLink>
        </div>
      </div>
      <div className="nav-items-right">
        <div onClick={languageSwitcher} className="nav-item nav-language">
          {t("common.language")}
        </div>
        <div className="nav-item nav-logout" onClick={handleLogout}>
          {isAuthenticated ? t("common.logout") : t("common.login")}
        </div>
        <div className="nav-burger" onClick={toggleMenu}>
          <div
            className={`burger-icon ${
              showDropDownMenu ? "activeDropDown" : ""
            }`}
          >
            &#9776;
          </div>
          {showDropDownMenu && (
            <>
              <div className="dropdown-menu">
                <NavLink
                  to={{
                    pathname: routes.USER_ACCOUNT,
                    search: location.search,
                  }}
                  className="dropdown-item"
                >
                  <img
                    src={userIcon}
                    alt="user-account"
                    className="dropdown-icon"
                  />
                  {t("common.userAccount")}
                </NavLink>
                <NavLink
                  to={{
                    pathname: routes.ACTIVITY_LIST,
                    search: location.search,
                  }}
                  className="dropdown-item"
                >
                  <img
                    src={listIcon}
                    alt="activity-list"
                    className="dropdown-icon"
                  />
                  {t("common.activityList")}
                </NavLink>
                <div onClick={languageSwitcher} className="dropdown-item">
                  <img
                    src={languageIcon}
                    alt="language switcher"
                    className="dropdown-icon"
                  />
                  {t("common.language")}
                </div>
                {isAuthenticated && (
                  <BlueButton
                    className="dropdown-logout"
                    onClick={handleLogout}
                  >
                    {isAuthenticated ? t("common.logout") : t("common.login")}
                  </BlueButton>
                )}
              </div>
              <div
                className="backdrop"
                id="backdrop"
                onClick={toggleMenu}
              ></div>
            </>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
