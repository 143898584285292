import React from "react";

// scss
import "../../scss/components/input-fields/Switch.scss";

/**
 * return Switch UI
 * @param {object} props
 * @return {JSX.Element}
 * @constructor
 */

function Switch({ value, onChange }) {
  return (
    <input
      type="checkbox"
      className="switch-component"
      checked={value}
      onChange={onChange}
    />
  );
}

export default Switch;
