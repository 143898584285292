import React, { forwardRef, useImperativeHandle, useState } from "react";

// stripe card element and hooks
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

// stripe card element customization
const cardOptions = {
  style: {
    base: {
      iconColor: "#34a9e1", // icon color
      color: "black", // text color
      fontWeight: 500,
      fontFamily: "Open Sans",
      fontSize: "15px",
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#bebebe",
      },
      textTransform: "uppercase",
      lineHeight: "40px",
    },
    complete: {
      iconColor: "green", // icon color
      color: "green", // text color
    },
    invalid: {
      iconColor: "red",
      color: "red",
    },
  },
  iconStyle: "solid", // solid or default
  hidePostalCode: true, // don't collect post code
  disableLink: true,
};

// credit card box (forwardRef component)
const CreditCard = forwardRef(({ name }, ref) => {
  // stripe hooks to retrieve payment method information
  const stripe = useStripe();
  const elements = useElements();

  // expose the function to call handlePay
  useImperativeHandle(ref, () => ({
    handlePay: handlePay,
  }));

  // validation error message
  const [message, setMessage] = useState(null);

  // listen for changes in the CardElement
  const handleChange = (e) => {
    if (e.error) {
      setMessage(e.error.message);
    } else {
      setMessage(null);
    }
  };

  // create stripe payment method
  // eslint-disable-next-line no-unused-vars
  const handlePay = async () => {
    // make sure stripe promise has loaded
    if (!stripe || !elements) return;

    // create payment method
    const res = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        name: name,
      },
    });

    // check if method has been created correctly
    if (res.error) {
      setMessage(res.error.message);
      return null;
    }

    // return created payment method
    return res.paymentMethod;
  };

  // render card element and error message
  return (
    <div className="credit-card">
      <CardElement options={cardOptions} onChange={handleChange} />
      {message?.length > 0 && <div className="err-message">{message}</div>}
    </div>
  );
});

export default CreditCard;
