/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useTranslation } from "react-i18next";

// scss
import "../../scss/views/Registration/Disclaimer.scss";

function Disclaimer({ message }) {
  const { t } = useTranslation(); // translation

  return (
    <div className="disclaimer">
      <h2> {t("registration.activityDisclaimer")}</h2>
      <div dangerouslySetInnerHTML={{ __html: message }}></div>
    </div>
  );
}

export default Disclaimer;
