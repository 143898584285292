import React from "react";
import { useTranslation } from "react-i18next";
import "../../scss/components/displayReuseable/DisplayStatus.scss";

import { activityStatus } from "../../utils/activityStatus";

const DisplayStatus = ({ act }) => {
  const { t } = useTranslation(); // translation
  const aStatus = activityStatus(act);
  if (aStatus === "status-join") {
    return <p className="status-open">{t("activityList.open")}</p>;
  } else if (aStatus === "status-waiting") {
    return <p className="status-waiting">{t("activityList.waiting")}</p>;
  } else if (aStatus === "status-full") {
    return <p className="status-full">{t("activityList.full")}</p>;
  } else {
    return "";
  }
};

export default DisplayStatus;
