import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// app context
import { useAuth } from "../../context/AuthContext";
import { useAppContext } from "../../context/AppContext";

// backend service
import api from "../../services/api";
import request from "../../services/Request";

// routes
import routes from "../../context/routes";

// styling
import "../../scss/views/Login/login.scss";

// password page
const Password = ({ email, changeView }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const { login } = useAuth();
  const { showNotification } = useAppContext();

  const [loading1, setLoading1] = useState(false); // loading (continue button)
  const [loading2, setLoading2] = useState(false); // loading (forgot password)
  const [error, setError] = useState(null); // warning message

  // handle forgot password
  const handleForgotPassword = async () => {
    if (loading1 || loading2) return;

    // redirect user to access code page
    try {
      setLoading2(true);
      const res = await request.post(api.login, {
        email: email,
        forgotPassword: true,
      });

      switch (res.status) {
        case 200:
          showNotification(
            "success",
            t("login.forgotPasswordTitle"),
            t("login.forgotPasswordMessage")
          );
          changeView(res.data.nextStep);
          break;

        default:
          throw new Error("system-error");
      }
    } catch (e) {
      if (e.message === "system-error") {
        showNotification("system");
      } else {
        showNotification("network");
      }
    } finally {
      setLoading2(false);
    }
  };

  // handle continue
  const handleContinue = async () => {
    if (loading1 || loading2) return;
    const password = document.getElementById("password").value;

    if (!password) {
      setError(t("login.passwordDes"));
      return;
    }

    // authenticate the user
    try {
      setLoading1(true);
      const res = await request.post(api.auth, {
        type: "auth-password",
        info: {
          email: email,
          password: password,
        },
      });

      switch (res.status) {
        case 200:
          login(res.data.userId, res.data.jwtToken);
          history.replace({
            pathname: routes.USER_ACCOUNT,
            search: location.search,
          });
          break;

        case 401:
          // password not matching
          setError(t("login.wrongPassword"));
          break;

        default:
          throw new Error("system-error");
      }
    } catch (e) {
      if (e.message === "system-error") {
        showNotification("system");
      } else {
        showNotification("network");
      }
    } finally {
      setLoading1(false);
    }
  };

  // handle back button
  const handleBack = async () => {
    if (loading1 || loading2) return;

    // back to email
    changeView("email");
  };

  // render page
  return (
    <>
      <div className="title">{t("login.password")}</div>
      <div className="content">{t("login.passwordDes")}</div>

      <div className="password-section">
        <div>{t("login.password")}</div>
        <div>
          {loading2 ? (
            <div className="loading" style={{ width: 20 }}></div>
          ) : (
            <div className="forgot-password" onClick={handleForgotPassword}>
              {t("login.forgotPassword")}
            </div>
          )}
        </div>
      </div>
      <input
        id="password"
        className="input-box"
        placeholder={t("login.passwordPlaceholder")}
        type="password"
        onChange={() => setError(null)}
        spellCheck="false"
        disabled={loading1 || loading2}
      />
      <div className="error-message">{error}</div>

      <div className="continue-button" onClick={handleContinue}>
        {!loading1 ? t("login.signIn") : <div className="loading"></div>}
      </div>
      <div className="back-button" onClick={handleBack}>
        {t("login.backToEmail")}
      </div>
    </>
  );
};

export default Password;
