import React from "react";
import { useTranslation } from "react-i18next";

// common components
import BlueButton from "../buttons/BlueButton";
import YellowButton from "../buttons/YellowButton";

// utils
import { activityStatus } from "../../utils/activityStatus";

// icons
import addPersonIcon from "../../assets/icons/addActivityIcon.png";
import personIcon from "../../assets/icons/capacity-white.png";

// style
import "../../scss/components/displayReuseable/StatusButton.scss";

// status button
const StatusButton = ({ goNext, activity, className, isDisableNextButton }) => {
  const { t } = useTranslation(); // translation
  const activityStatusType = activityStatus(activity);

  return (
    <div className={className}>
      {activityStatusType === "status-join" ? (
        <BlueButton
          className="btn join-session"
          disabled={isDisableNextButton}
          onClick={() => {
            if (!isDisableNextButton) goNext(false);
          }}
        >
          <img src={addPersonIcon} alt="" />
          {t("registration.joinTheSession")}
        </BlueButton>
      ) : activityStatusType === "status-waiting" ? (
        <YellowButton
          className="btn add-waiting-list"
          disabled={isDisableNextButton}
          onClick={() => {
            if (!isDisableNextButton) goNext(false);
          }}
        >
          <img src={personIcon} alt="" />
          {t("registration.addToWaitingList")}
        </YellowButton>
      ) : activityStatusType === "status-full" ? (
        <label className="session-full">{t("registration.sessionFull")}</label>
      ) : (
        ""
      )}
    </div>
  );
};
export default StatusButton;
