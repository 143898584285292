import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

// scss
import "../../scss/components/input-fields/InputDate.scss";

const InputDate = ({
  id,
  label,
  value,
  placeHolder,
  setter,
  validation,
  maxDate = "2200-01-01",
  tip = "",
  disabled = false,
}) => {
  const { t } = useTranslation(); //locale translation

  const datePickerRef = useRef(null);

  const onClickDatePicker = () => {
    // open picker
    datePickerRef.current.showPicker();
  };

  return (
    <div className="custom-input-date">
      <label htmlFor={id}>
        {label}
        <span>{tip}</span>
      </label>
      <div className="wrapper">
        <i onClick={onClickDatePicker} className="fa fa-calendar"></i>
        <input
          ref={datePickerRef}
          name={id}
          id={id}
          type="date"
          max={maxDate}
          value={value}
          placeholder={placeHolder}
          disabled={disabled}
          onClick={onClickDatePicker}
          onChange={(e) => setter(e.target.value, id)}
        />
      </div>

      {validation && !validation.valid && (
        <div className="validation-container">
          <span>{t(validation.message)}</span>
        </div>
      )}
    </div>
  );
};

export default InputDate;
