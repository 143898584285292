import React from "react";

import "../../scss/components/button/BlueButton.scss";

function BlueButton({ onClick, className, children, disabled = false }) {
  return (
    <button
      className={`blue-button ${className ? className : ""}`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export default BlueButton;
