const paymentInfo = {
  congratulations: "You only have one step left!",
  success: "Congratulations, your child has successfully registered!",
  invalidLink: "The payment link is invalid.",
  invalidLinkHint:
    "You might already made the payment and registered the activity successfully",
  selectedDays: "Selected days",
  transactionFee: "Platform Service Fee",
  activityFee: "Activity Fee",
  total: "Total",
  pay: "Confirm & Pay",
  available: "Available",
  makePayment: "Payment",
  registrationSuccessful: "Successful",
};

export default paymentInfo;
