const validationTemplate = {
  childName: {
    rules: ["required"],
    message: "",
    valid: true,
  },
  birthday: {
    rules: ["required"],
    message: "",
    valid: true,
  },
  gender: {
    rules: ["required"],
    message: "",
    valid: true,
  },
  address: {
    rules: ["required"],
    message: "",
    valid: true,
  },
  city: {
    rules: ["required"],
    message: "",
    valid: true,
  },
  province: {
    rules: ["required"],
    message: "",
    valid: true,
  },
  postCode: {
    rules: ["required"],
    message: "",
    valid: true,
  },
  doctorName: {
    rules: ["required"],
    message: "",
    valid: true,
  },
  careCard: {
    rules: ["required"],
    message: "",
    valid: true,
  },
  allergies: {
    rules: [""],
    message: "",
    valid: true,
  },
  disabilities: {
    rules: [""],
    message: "",
    valid: true,
  },
  medical: {
    rules: [""],
    message: "",
    valid: true,
  },
  specialNeeds: {
    rules: [""],
    message: "",
    valid: true,
  },
  swimming: {
    rules: ["required"],
    message: "",
    valid: true,
  },
  consent_0: {
    rules: ["required"],
    message: "",
    valid: true,
  },
  consent_1: {
    rules: ["required"],
    message: "",
    valid: true,
  },
  consent_2: {
    rules: ["required"],
    message: "",
    valid: true,
  },
  consent_3: {
    rules: ["required"],
    message: "",
    valid: true,
  },
  consent_4: {
    rules: ["required"],
    message: "",
    valid: true,
  },
  consent_5: {
    rules: ["required"],
    message: "",
    valid: true,
  },
  valid: null,
};

export default validationTemplate;
