import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// context and routes
import { useAuth } from "../../context/AuthContext";
import { useAppContext } from "../../context/AppContext";
import routes from "../../context/routes";

// backend service
import api from "../../services/api";
import request from "../../services/Request";

// components
import BackLine from "../../components/backLine/BackLine";
import DisplayStatus from "../../components/displayReuseable/DisplayStatus";
import StatusButton from "../../components/displayReuseable/StatusButton";

// icons
import defaultImg from "../../assets/imgs/default-img.png";
import materialEvent from "../../assets/icons/material-event.png";
import noDataIcon from "../../assets/icons/no-data-icon.png";

// utils
import { formatDateMonth } from "../../utils/tools";

// scss
import "../../scss/views/ActivityList/ActivityList.scss";

// sub-component
const Activity = ({ act }) => {
  const location = useLocation();
  const history = useHistory();
  const { updateUserData } = useAppContext();

  const goNext = (isWaiting) => {
    if (isWaiting) {
      history.push({ pathname: routes.USER_ACCOUNT, search: location.search });
    } else {
      updateUserData({
        selectedActivity: act,
      });
      history.push({
        pathname: routes.ACTIVITY_REGISTRATION,
        search: location.search,
      });
    }
  };

  return (
    <div className="activity">
      <div className="info">
        <img
          style={{ objectFit: "cover" }}
          src={act.info.imageUrl || defaultImg}
          alt={act.info.name}
        />
        <div className="name">{act.info.name}</div>
      </div>
      <div className="details">
        <div className="details__schedule">
          <div className="details__schedule__date">
            {`${formatDateMonth(act.fromDate)} - ${formatDateMonth(
              act.toDate
            )}`}
          </div>
          <div className="details__schedule__time">
            {act.info.startTime} - {act.info.endTime}
          </div>
        </div>
        <div className="details__capacity">
          <img src={materialEvent} alt="capacity-icon" />
          <label className="count"> {act.info.capacity}</label>
        </div>
        <div className="details__daily-status">
          {<DisplayStatus act={act} />}
        </div>
        <StatusButton
          className="details__addActivityButton"
          goNext={goNext}
          activity={act}
          isDisableNextButton={false}
        />
      </div>
    </div>
  );
};

// activity list page
const ActivityList = () => {
  const { t } = useTranslation();

  const { jwtToken } = useAuth();
  const {
    entryPoint,
    centerId,
    userData,
    showLoading,
    showNotification,
    updateUserData,
  } = useAppContext();

  const [activitiesList, setActivitiesList] = useState([]);

  // load data
  useEffect(() => {
    const loadData = async () => {
      try {
        showLoading(true);
        const res = await request.get(
          api.activityList,
          {
            centerId: centerId,
          },
          jwtToken
        );

        switch (res.status) {
          case 200:
            setActivitiesList(res.data.activities);
            updateUserData({
              centerInfo: {
                name: res.data.name,
                calcTrans: res.data.calcTrans,
                sAccount: res.data.sAccount,
                sRate: res.data.sRate,
              },
            });
            break;

          case 403:
            showNotification("expired");
            break;

          default:
            throw new Error("system-error");
        }
      } catch (e) {
        if (e.message === "system-error") {
          showNotification("system");
        } else {
          showNotification("network");
        }
      } finally {
        showLoading(false);
      }
    };

    // load activity list
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="center-activity-list">
      {entryPoint === "super" && <BackLine route={routes.CENTER_LIST} />}
      <div className="center-name">{userData?.centerInfo?.name}</div>

      <div className="label">
        <h2>{t("activityList.campInfo")}</h2>
        <div className="label__info">
          <h2>{t("activityList.campTime")}</h2>
          <h2>{t("activityList.capacity")}</h2>
          <h2>{t("activityList.status")}</h2>
          <h2>{""}</h2>
        </div>
      </div>

      <div className="activities">
        {activitiesList?.length > 0 ? (
          activitiesList.map((item) => {
            return <Activity key={item.activityId} act={item} />;
          })
        ) : (
          <div className="no-data-container">
            <img src={noDataIcon} alt="no data found" />
            {t("activityList.noResult")}
          </div>
        )}
      </div>
    </div>
  );
};

export default ActivityList;
