import React from "react";

import "../../scss/components/modals/CenterModal.scss";

/**
 * @return{React.Component}
 */
function CenterModal({ children, shouldDisplay }) {
  return (
    <div
      className={shouldDisplay ? "center-modal-display" : "center-modal-hide"}
    >
      <div className="center-modal-content"> {children}</div>
    </div>
  );
}

export default CenterModal;
